import {useCroct} from '@croct/plug-react';
import {useCallback} from 'react';

export function useGoalCompletedTracker(): (goalId: string) => Promise<void> {
    const croct = useCroct();

    return useCallback(
        (goalId: string) => new Promise(resolve => {
            const patch = croct.user.edit();

            croct.track('goalCompleted', {
                goalId: goalId,
            });

            patch.add('activities', goalId);

            patch.save().finally(resolve);
        }),
        [croct],
    );
}
