import {FunctionComponent} from 'react';
import {Navigate as RouterNavigate, useLocation} from 'react-router-dom';
import {useRouter} from '../../hooks/useRouter';
import {To} from '../RouterProvider';

type NavigateProps = {
    to: To,
    state?: Record<string, any>,
};

export const Navigate: FunctionComponent<NavigateProps> = ({to, state}) => {
    const {pathname} = useLocation();
    const {createPath} = useRouter();

    return (
        <RouterNavigate
            to={createPath(to)}
            state={{
                ...state,
                from: pathname,
            }}
        />
    );
};
