import {ReactElement, ReactNode, Fragment} from 'react';
import {
    usePermission as useLazyPermission,
    UsePermissionProps,
    usePreloadedPermission,
    UsePreloadedPermissionsProps,
} from '../../hooks/usePermission';

type HookProps = UsePermissionProps | UsePreloadedPermissionsProps;

type CanProps = HookProps & {
    yes?: ReactNode,
    no?: ReactNode,
};

type LazyCanProps = UsePermissionProps & Pick<CanProps, 'no' | 'yes'>;

function LazyCan(props: LazyCanProps): ReactElement {
    const {yes, no, ...rest} = props;
    const isGranted = useLazyPermission(rest);

    return (
        <Fragment>
            {isGranted ? yes : no}
        </Fragment>
    );
}

type PreloadedCanProps = UsePreloadedPermissionsProps & Pick<CanProps, 'no' | 'yes'>;

function PreloadedCan(props: PreloadedCanProps): ReactElement {
    const {yes, no, ...rest} = props;
    const isGranted = usePreloadedPermission(rest);

    return (
        <Fragment>
            {isGranted ? yes : no}
        </Fragment>
    );
}

function isPreloaded(props: CanProps): props is PreloadedCanProps {
    return 'queryRef' in props && props.queryRef !== undefined;
}

export function Can(props: CanProps): ReactElement {
    return isPreloaded(props) ? <PreloadedCan {...props} /> : <LazyCan {...props} />;
}
