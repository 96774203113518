import {Link, LinkProps} from '@croct-tech/application-ui/components/Link';
import {ReactNode} from 'react';

type MarkdownFragmentMap = {
    'bold': {
        children: MarkdownFragment,
    },
    'text': {
        text: string,
    },
    'link': {
        href: string,
        appearance?: LinkProps['appearance'],
        underlined?: boolean,
        children: MarkdownFragment,
    },
    'fragment': {
        children: MarkdownFragment[],
    },
};

type MarkdownFragmentType = keyof MarkdownFragmentMap;

type MarkdownFragment<T extends MarkdownFragmentType = MarkdownFragmentType> = {
    [K in MarkdownFragmentType]: MarkdownFragmentMap[K] & {
        type: K,
    }
}[T];

type MarkdownOptions = {
    link?: {
        appearance?: LinkProps['appearance'],
        underlined?: boolean,
    },
};

function parseMarkdown(markdown: string): MarkdownFragment {
    const fragments = markdown.split(/(\[.*]\(.*\)|\*\*.*\*\*)/g);
    const elements: MarkdownFragment[] = [];

    for (const fragment of fragments) {
        if (fragment === '') {
            continue;
        }

        if (fragment.startsWith('**')) {
            elements.push({
                type: 'bold',
                children: parseMarkdown(fragment.slice(2, -2)),
            });

            continue;
        }

        if (fragment.startsWith('[')) {
            const match = fragment.match(/\[(.*)]\((.*)\)/)!;

            elements.push({
                type: 'link',
                href: match[2],
                children: parseMarkdown(match[1]),
            });

            continue;
        }

        elements.push({
            type: 'text',
            text: fragment,
        });
    }

    if (elements.length === 1) {
        return elements[0];
    }

    return {
        type: 'fragment',
        children: elements,
    };
}

function renderNodes(nodes: MarkdownFragment, options?: MarkdownOptions, index?: number): ReactNode {
    const key = nodes.type + (index !== undefined ? index : '');

    switch (nodes.type) {
        case 'bold':
            return (
                <strong key={key}>
                    {renderNodes(nodes.children)}
                </strong>
            );

        case 'text':
            return nodes.text;

        case 'link':
            return (
                <Link
                    key={key}
                    href={nodes.href}
                    appearance={options?.link?.appearance}
                    underlined={options?.link?.underlined}
                >
                    {renderNodes(nodes.children)}
                </Link>
            );

        case 'fragment':
            return nodes.children.map((item, itemIndex) => renderNodes(item, options, itemIndex));
    }
}

export function renderMarkdown(markdown: string, options?: MarkdownOptions): ReactNode {
    return renderNodes(parseMarkdown(markdown), options, undefined);
}
