import {MouseEvent, useCallback, forwardRef, AnchorHTMLAttributes, ReactNode, ElementType} from 'react';
import {Box, GenericComponent} from '@croct-tech/application-ui/components/Box';
import {createPath as createUrlPath} from 'history';
import {useLocation} from 'react-router';
import {useRouter} from '../../hooks/useRouter';
import {To} from '../RouterProvider';

type InheritedProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'style'>;

export type RouteLinkProps = InheritedProps & {
    to: To,
    replace?: boolean,
    state?: Record<string, any>,
    children?: ReactNode,
};

function isModifiedEvent(event: MouseEvent): boolean {
    return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}

export const RouteLink: GenericComponent<RouteLinkProps, ElementType, 'a'> = forwardRef((props, ref) => {
    const {as = 'a', to, target, onClick, children, replace, state, ...rest} = props;

    const {navigate, createPath} = useRouter();
    const location = useLocation();
    const href = (process.env.REACT_APP_BASE_PATH ?? '') + createPath(to);

    const handleClick = useCallback(
        (event: MouseEvent<HTMLAnchorElement>) => {
            onClick?.(event);

            if (
                // onClick prevented default
                !event.defaultPrevented
                // Ignore everything but the main button (usually the left button)
                && event.button === 0
                // Let browser handle "target=_blank" etc.
                && (target === undefined || target === '_self')
                // Ignore clicks with modifier keys
                && !isModifiedEvent(event)
            ) {
                event.preventDefault();

                // If the URL hasn't changed, a regular <a> will do a replace instead of
                // a push, so do the same here.
                const isReplace = (replace !== undefined && replace)
                    || createUrlPath(location) === href;

                navigate(to, {replace: isReplace, state: state});
            }
        },
        [onClick, target, navigate, to, href, state, location, replace],
    );

    return (
        <Box<ElementType>
            {...rest}
            as={as}
            href={href}
            onClick={handleClick}
            ref={ref}
            target={target}
        >
            {children}
        </Box>
    );
});
