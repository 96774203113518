import {useMemo} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useLazyLoadQuery} from 'react-relay';
import {useParams} from 'react-router-dom';
import {
    Expertise,
    useSlotContextQuery,
    useSlotContextQuery$data as QueryData,
} from './__generated__/useSlotContextQuery.graphql';
import {useScope} from './useScope';

export type ScopeContext = {
    area: 'user' | 'organization' | 'workspace' | 'application' | 'experience' | 'experiment',
    user?: {
        id: string,
        name: string,
        firstName: string,
        username: string,
        since: number,
        organizationCount: number,
        expertise: Expertise | null,
    },
    organization?: {
        name: string,
        slug: string,
        creation: number,
        role: string | null,
        memberCount: number,
        plan: string,
        features?: string[],
        quota: {
            dynamicAttributesPerContent: number | null,
            nodesPerDefinition: number | null,
            nodesPerContent: number | null,
            audiencesPerExperience: number | null,
            workspaces: {
                total: number | null,
                used: number | null,
            },
            monthlyActiveUsers: {
                total: number | null,
                used: number | null,
            },
        },
    },
    workspace?: {
        name: string,
        slug: string,
        creation: number,
        role: string | null,
        memberCount: number,
        totalExperienceCount: number,
        activeExperienceCount: number,
        totalExperimentCount: number,
        activeExperimentCount: number,
        quota: {
            applications: {
                total: number | null,
                used: number | null,
            },
            audiences: {
                total: number | null,
                used: number | null,
            },
            components: {
                total: number | null,
                used: number | null,
            },
            experiences: {
                total: number | null,
                used: number | null,
            },
            experiments: {
                total: number | null,
                used: number | null,
            },
            locales: {
                total: number | null,
                used: number | null,
            },
            slots: {
                total: number | null,
                used: number | null,
            },
        },
    },
    application?: {
        name: string,
        creation: number,
    },
    experience?: {
        name: string,
        status: string,
        audienceCount: number,
        currentExperiment: {
            name: string | null,
            status: string | null,
        },
    },
    experiment?: {
        name: string,
        status: string,
        variantCount: number,
    },
};

export function useSlotContext(): ScopeContext | null {
    const {organizationId, workspaceId, applicationId, user: {id: userId}} = useScope();
    const {experienceId, experimentId} = useParams<'personalization.experiences.experiments.edit'>();

    const {contextOverview} = useLazyLoadQuery<useSlotContextQuery>(
        graphql`
            query useSlotContextQuery(
                $payload: ContextOverviewPayload!
            ) @raw_response_type {
                contextOverview(payload: $payload) {
                    user {
                        userId
                        name
                        username
                        organizationCount
                        accountCreationTime
                        expertise
                    }
                    organization {
                        name
                        slug
                        role
                        creationDate
                        membershipCount
                        plan
                        features
                        consumableQuotas {
                            monthlyActiveUsers {
                                total
                                used
                            }
                            workspaces {
                                total
                                used
                            }
                        }
                        nonConsumableQuotas {
                            nodesPerContent
                            nodesPerDefinition
                            audiencesPerExperience
                            dynamicAttributesPerContent
                        }
                        workspace {
                            name
                            slug
                            role
                            creationDate
                            membershipCount
                            consumableQuotas {
                                applications {
                                    total
                                    used
                                }
                                audiences {
                                    total
                                    used
                                }
                                components {
                                    total
                                    used
                                }
                                experiences {
                                    total
                                    used
                                }
                                experiments {
                                    total
                                    used
                                }
                                locales {
                                    total
                                    used
                                }
                                slots {
                                    total
                                    used
                                }
                            }
                            experienceCount {
                                total
                                active
                            }
                            experimentCount {
                                total
                                active
                            }
                            experience {
                                name
                                status
                                audienceCount
                                currentExperimentName
                                currentExperimentStatus
                                experiment {
                                    name
                                    status
                                    variantCount
                                }
                            }
                            application {
                                name
                                creationDate
                            }
                        }
                    }
                }
            }
        `,
        {
            payload: {
                organizationId: organizationId,
                workspaceId: workspaceId,
                applicationId: applicationId,
                experienceId: experienceId,
                experimentId: experimentId,
            },
        },
        {
            fetchPolicy: 'store-and-network',
            fetchKey: userId ?? undefined,
        },
    );

    const {user, organization = null} = contextOverview;

    return useMemo(
        () => {
            if (user === null) {
                return null;
            }

            const context: ScopeContext = {
                area: getScope(organization),
                user: {
                    id: user.userId,
                    name: user.name,
                    firstName: extractFirstName(user.name),
                    username: user.username,
                    since: user.accountCreationTime,
                    organizationCount: user.organizationCount,
                    expertise: user.expertise,
                },
            };

            if (organization !== null) {
                const {consumableQuotas: quotas, nonConsumableQuotas: limits} = organization;

                context.organization = {
                    name: organization.name,
                    slug: organization.slug,
                    creation: organization.creationDate,
                    role: organization.role?.toLowerCase() ?? null,
                    memberCount: organization.membershipCount,
                    plan: organization.plan,
                    features: organization.features !== undefined
                        // Transform read-only array to mutable array
                        ? Array.from(organization.features, item => item.toLowerCase())
                        : undefined,
                    quota: {
                        dynamicAttributesPerContent: limits?.dynamicAttributesPerContent ?? null,
                        nodesPerDefinition: limits?.nodesPerDefinition ?? null,
                        nodesPerContent: limits?.nodesPerContent ?? null,
                        audiencesPerExperience: limits?.audiencesPerExperience ?? null,
                        monthlyActiveUsers: {
                            total: quotas?.monthlyActiveUsers?.total ?? null,
                            used: quotas?.monthlyActiveUsers?.used ?? null,
                        },
                        workspaces: {
                            total: quotas?.workspaces?.total ?? null,
                            used: quotas?.workspaces?.used ?? null,
                        },
                    },
                };
            }

            const {workspace = null} = organization ?? {};

            if (workspace !== null) {
                const {consumableQuotas: quotas} = workspace;

                context.workspace = {
                    name: workspace.name,
                    slug: workspace.slug,
                    creation: workspace.creationDate,
                    role: workspace.role?.toLowerCase() ?? null,
                    memberCount: workspace.membershipCount,
                    totalExperienceCount: workspace.experienceCount.total,
                    activeExperienceCount: workspace.experienceCount.active,
                    totalExperimentCount: workspace.experimentCount.total,
                    activeExperimentCount: workspace.experimentCount.active,
                    quota: {
                        applications: {
                            total: quotas?.applications.total ?? null,
                            used: quotas?.applications.used ?? null,
                        },
                        audiences: {
                            total: quotas?.audiences.total ?? null,
                            used: quotas?.audiences.used ?? null,
                        },
                        components: {
                            total: quotas?.components.total ?? null,
                            used: quotas?.components.used ?? null,
                        },
                        experiences: {
                            total: quotas?.experiences.total ?? null,
                            used: quotas?.experiences.used ?? null,
                        },
                        experiments: {
                            total: quotas?.experiments.total ?? null,
                            used: quotas?.experiments.used ?? null,
                        },
                        locales: {
                            total: quotas?.locales.total ?? null,
                            used: quotas?.locales.used ?? null,
                        },
                        slots: {
                            total: quotas?.slots.total ?? null,
                            used: quotas?.slots.used ?? null,
                        },
                    },
                };
            }

            const {application = null, experience = null} = workspace ?? {};

            if (application !== null) {
                context.application = {
                    name: application.name,
                    creation: application.creationDate,
                };
            }

            if (experience !== null) {
                context.experience = {
                    name: experience.name,
                    status: experience.status.toLowerCase(),
                    audienceCount: experience.audienceCount,
                    currentExperiment: {
                        name: experience.currentExperimentName ?? null,
                        status: experience.currentExperimentStatus?.toLowerCase() ?? null,
                    },
                };
            }

            const {experiment = null} = experience ?? {};

            if (experiment !== null) {
                context.experiment = {
                    name: experiment.name,
                    status: experiment.status.toLowerCase(),
                    variantCount: experiment.variantCount,
                };
            }

            return context;
        },
        [organization, user],
    );
}

function getScope(organization: QueryData['contextOverview']['organization']): ScopeContext['area'] {
    if ((organization?.workspace?.experience?.experiment ?? null) !== null) {
        return 'experiment';
    }

    if ((organization?.workspace?.experience ?? null) !== null) {
        return 'experience';
    }

    if ((organization?.workspace?.application ?? null) !== null) {
        return 'application';
    }

    if ((organization?.workspace ?? null) !== null) {
        return 'workspace';
    }

    if ((organization ?? null) !== null) {
        return 'organization';
    }

    return 'user';
}

function extractFirstName(fullName: string): string {
    const firstName = fullName
        .trim()
        .split(' ')[0]
        .toLowerCase();

    return firstName.charAt(0).toUpperCase() + firstName.slice(1);
}
