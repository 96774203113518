import {
    CacheConfig,
    Environment,
    IEnvironment,
    Network,
    Observable,
    RecordSource,
    RequestParameters,
    Store,
    Variables,
} from 'relay-runtime';
import {Sink} from 'relay-runtime/lib/network/RelayObservable';

export type RelayNode<T extends Record<string, any>> = {
    node: T | null,
};

export type RelayConnection<T extends Record<string, any>> = {
    edges: ReadonlyArray<RelayNode<T> | null> | null,
};

export type ExtractTypeFromConnection<T extends RelayConnection<any>> =
    T extends RelayConnection<infer N>
        ? N
        : never;

export enum AccessDeniedReason {
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    UNKNOWN_USER = 'UNKNOWN_USER',
    BAD_CREDENTIALS = 'BAD_CREDENTIALS',
    UNVERIFIED_USER = 'UNVERIFIED_USER',
}

export enum ProblemType {
    UNEXPECTED_ERROR = 'https://croct.help/api/admin#unexpected-error',
    INVALID_INPUT = 'https://croct.help/api/admin#invalid-input',
    AUTHENTICATION_REQUIRED = 'https://croct.help/api/admin#authentication-required',
    ACCESS_DENIED = 'https://croct.help/api/admin#access-denied',
    RESOURCE_NOT_FOUND = 'https://croct.help/api/admin#resource-not-found',
    FAILED_PRECONDITION = 'https://croct.help/api/admin#failed-precondition',
    OPERATION_CONFLICT = 'https://croct.help/api/admin#operation-conflict',
    UNAVAILABLE_FEATURE = 'https://croct.help/api/admin#unavailable-feature',
}

type User = {
    id: string,
    username: string,
    firstName: string,
    lastName: string,
};

type LastEditor = {
    isActor: boolean,
    user: User | null,
};

type ExperienceRevisionId = string;

export type Conflict = {
    currentRevision: ExperienceRevisionId,
    providedRevision: ExperienceRevisionId,
    lastEditor: LastEditor,
};

type Problem = {
    type: ProblemType,
    message: string,
    detail: string,
    status: number,
    conflict?: Conflict,
    reason?: AccessDeniedReason,
};

const isMutation = (request: RequestParameters): boolean => request.operationKind === 'mutation';

const RETRY_INTERVAL = 5000;
const MAX_RETRIES = 50;

type RetryableFetchOptions = {
    maxRetries?: number,
    retryInterval?: number,
};

function retryableFetch(url: string, options: RequestInit, retryOptions: RetryableFetchOptions): Promise<Response> {
    const {maxRetries = MAX_RETRIES, retryInterval = RETRY_INTERVAL} = retryOptions;

    return new Promise<Response>((resolve, reject) => {
        const next = (attempt: number): void => {
            const retry = (error: any): void => {
                if (attempt < maxRetries) {
                    // If the user is offline, continue trying without limit.
                    setTimeout(() => next(navigator.onLine ? attempt + 1 : attempt), retryInterval);

                    return;
                }

                reject(error);
            };

            fetch(url, options).then(response => {
                // Retry on any non-200 response
                if (!response.ok) {
                    retry(response);

                    return;
                }

                resolve(response);
            }).catch(retry);
        };

        next(0);
    });
}

const fetchQuery = (
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    sink: Sink<any>,
    options: RetryableFetchOptions,
): Promise<void> => {
    const body = JSON.stringify({
        query: request.text,
        variables: variables,
    });

    const headers = {
        Accept: 'application/json',
        'Content-type': 'application/json',
    };

    const fetchStrategy = request.operationKind === 'query' ? retryableFetch : fetch;

    return fetchStrategy(
        process.env.REACT_APP_ADMIN_ENDPOINT !== undefined
            ? `${process.env.REACT_APP_ADMIN_ENDPOINT}/graphql`
            : 'http://localhost:4000/graphql',
        {
            method: 'POST',
            credentials: 'include',
            headers: headers,
            body: body,
        },
        options,
    ).then(async response => {
        const data = await response.json();

        if (isMutation(request) && data.errors != null) {
            sink.error(data);
            sink.complete();

            return;
        }

        sink.next(data);
        sink.complete();
    }).catch(error => {
        sink.error(error, true);
    });
};

const executeFunction = (
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    options: RetryableFetchOptions,
): Observable<any> => Observable.create(sink => {
    fetchQuery(request, variables, cacheConfig, sink, options);
});

type EnvironmentOptions = RetryableFetchOptions;

export function createEnvironment(options: EnvironmentOptions = {}): IEnvironment {
    return new Environment({
        network: Network.create(
            (request, variables, cacheConfig) => (
                executeFunction(request, variables, cacheConfig, options)
            ),
        ),
        store: new Store(new RecordSource()),
    });
}

function extractProblems(error: Error): Problem[] {
    const errors = (error as any)?.errors ?? (error as any).source?.errors;

    if (!Array.isArray(errors)) {
        return [];
    }

    return errors.map(sourceError => sourceError?.extensions);
}

export function getAccessDeniedReason(error: Error): AccessDeniedReason | null {
    return extractProblems(error).find(problem => problem.type === ProblemType.ACCESS_DENIED)?.reason ?? null;
}

export function getConflict(error: Error): Conflict | null {
    return extractProblems(error).find(problem => problem.type === ProblemType.OPERATION_CONFLICT)?.conflict ?? null;
}

export function isErrorType(error: Error, type: ProblemType): boolean {
    return extractProblems(error).some(problem => problem.type === type);
}

export function getProblemType(error: Error): ProblemType {
    switch (true) {
        case isErrorType(error, ProblemType.ACCESS_DENIED):
            return ProblemType.ACCESS_DENIED;

        case isErrorType(error, ProblemType.UNEXPECTED_ERROR):
            return ProblemType.UNEXPECTED_ERROR;

        case isErrorType(error, ProblemType.AUTHENTICATION_REQUIRED):
            return ProblemType.AUTHENTICATION_REQUIRED;

        case isErrorType(error, ProblemType.FAILED_PRECONDITION):
            return ProblemType.FAILED_PRECONDITION;

        case isErrorType(error, ProblemType.INVALID_INPUT):
            return ProblemType.INVALID_INPUT;

        case isErrorType(error, ProblemType.OPERATION_CONFLICT):
            return ProblemType.OPERATION_CONFLICT;

        case isErrorType(error, ProblemType.UNAVAILABLE_FEATURE):
            return ProblemType.UNAVAILABLE_FEATURE;

        default:
            return ProblemType.RESOURCE_NOT_FOUND;
    }
}
