/**
 * @generated SignedSource<<33f420564cc47d0db110b0ca93c3423b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExperienceStatus = "ACTIVE" | "ARCHIVED" | "DRAFT" | "PAUSED" | "SCHEDULED";
export type ExperimentStatus = "ACTIVE" | "DRAFT" | "FINISHED" | "INDIRECTLY_PAUSED" | "PAUSED" | "SCHEDULED";
export type Expertise = "DESIGN" | "ENGINEERING" | "MARKETING" | "OTHER" | "PRODUCT";
export type Feature = "API_DATA_EXPORT" | "CROSS_DEVICE_EXPERIMENT" | "EXPERIMENT" | "LOCATION" | "PMS" | "SCHEDULING";
export type OrganizationRole = "CUSTOM" | "MAINTAINER" | "MANAGER" | "OWNER";
export type WorkspaceRole = "CUSTOM" | "MAINTAINER" | "MANAGER" | "OWNER";
export type ContextOverviewPayload = {
  applicationId?: any | null;
  experienceId?: any | null;
  experimentId?: any | null;
  organizationId?: any | null;
  workspaceId?: any | null;
};
export type useSlotContextQuery$variables = {
  payload: ContextOverviewPayload;
};
export type useSlotContextQuery$data = {
  readonly contextOverview: {
    readonly organization: {
      readonly consumableQuotas: {
        readonly monthlyActiveUsers: {
          readonly total: number;
          readonly used: number;
        };
        readonly workspaces: {
          readonly total: number;
          readonly used: number;
        };
      };
      readonly creationDate: number;
      readonly features: ReadonlyArray<Feature>;
      readonly membershipCount: number;
      readonly name: string;
      readonly nonConsumableQuotas: {
        readonly audiencesPerExperience: number;
        readonly dynamicAttributesPerContent: number;
        readonly nodesPerContent: number;
        readonly nodesPerDefinition: number;
      };
      readonly plan: string;
      readonly role: OrganizationRole | null;
      readonly slug: string;
      readonly workspace: {
        readonly application: {
          readonly creationDate: number;
          readonly name: string;
        } | null;
        readonly consumableQuotas: {
          readonly applications: {
            readonly total: number;
            readonly used: number;
          };
          readonly audiences: {
            readonly total: number;
            readonly used: number;
          };
          readonly components: {
            readonly total: number;
            readonly used: number;
          };
          readonly experiences: {
            readonly total: number;
            readonly used: number;
          };
          readonly experiments: {
            readonly total: number;
            readonly used: number;
          };
          readonly locales: {
            readonly total: number;
            readonly used: number;
          };
          readonly slots: {
            readonly total: number;
            readonly used: number;
          };
        };
        readonly creationDate: number;
        readonly experience: {
          readonly audienceCount: number;
          readonly currentExperimentName: string | null;
          readonly currentExperimentStatus: ExperimentStatus | null;
          readonly experiment: {
            readonly name: string;
            readonly status: ExperimentStatus;
            readonly variantCount: number;
          } | null;
          readonly name: string;
          readonly status: ExperienceStatus;
        } | null;
        readonly experienceCount: {
          readonly active: number;
          readonly total: number;
        };
        readonly experimentCount: {
          readonly active: number;
          readonly total: number;
        };
        readonly membershipCount: number;
        readonly name: string;
        readonly role: WorkspaceRole | null;
        readonly slug: string;
      } | null;
    } | null;
    readonly user: {
      readonly accountCreationTime: number;
      readonly expertise: Expertise | null;
      readonly name: string;
      readonly organizationCount: number;
      readonly userId: string;
      readonly username: string;
    } | null;
  };
};
export type useSlotContextQuery$rawResponse = {
  readonly contextOverview: {
    readonly organization: {
      readonly consumableQuotas: {
        readonly monthlyActiveUsers: {
          readonly total: number;
          readonly used: number;
        };
        readonly workspaces: {
          readonly total: number;
          readonly used: number;
        };
      };
      readonly creationDate: number;
      readonly features: ReadonlyArray<Feature>;
      readonly membershipCount: number;
      readonly name: string;
      readonly nonConsumableQuotas: {
        readonly audiencesPerExperience: number;
        readonly dynamicAttributesPerContent: number;
        readonly nodesPerContent: number;
        readonly nodesPerDefinition: number;
      };
      readonly plan: string;
      readonly role: OrganizationRole | null;
      readonly slug: string;
      readonly workspace: {
        readonly application: {
          readonly creationDate: number;
          readonly name: string;
        } | null;
        readonly consumableQuotas: {
          readonly applications: {
            readonly total: number;
            readonly used: number;
          };
          readonly audiences: {
            readonly total: number;
            readonly used: number;
          };
          readonly components: {
            readonly total: number;
            readonly used: number;
          };
          readonly experiences: {
            readonly total: number;
            readonly used: number;
          };
          readonly experiments: {
            readonly total: number;
            readonly used: number;
          };
          readonly locales: {
            readonly total: number;
            readonly used: number;
          };
          readonly slots: {
            readonly total: number;
            readonly used: number;
          };
        };
        readonly creationDate: number;
        readonly experience: {
          readonly audienceCount: number;
          readonly currentExperimentName: string | null;
          readonly currentExperimentStatus: ExperimentStatus | null;
          readonly experiment: {
            readonly name: string;
            readonly status: ExperimentStatus;
            readonly variantCount: number;
          } | null;
          readonly name: string;
          readonly status: ExperienceStatus;
        } | null;
        readonly experienceCount: {
          readonly active: number;
          readonly total: number;
        };
        readonly experimentCount: {
          readonly active: number;
          readonly total: number;
        };
        readonly membershipCount: number;
        readonly name: string;
        readonly role: WorkspaceRole | null;
        readonly slug: string;
      } | null;
    } | null;
    readonly user: {
      readonly accountCreationTime: number;
      readonly expertise: Expertise | null;
      readonly name: string;
      readonly organizationCount: number;
      readonly userId: string;
      readonly username: string;
    } | null;
  };
};
export type useSlotContextQuery = {
  rawResponse: useSlotContextQuery$rawResponse;
  response: useSlotContextQuery$data;
  variables: useSlotContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "payload"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creationDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membershipCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "used",
    "storageKey": null
  }
],
v8 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "active",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "payload",
        "variableName": "payload"
      }
    ],
    "concreteType": "ContextData",
    "kind": "LinkedField",
    "name": "contextOverview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContext",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountCreationTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expertise",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationContext",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "plan",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "features",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConsumableQuotas",
            "kind": "LinkedField",
            "name": "consumableQuotas",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QuotaUsage",
                "kind": "LinkedField",
                "name": "monthlyActiveUsers",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "QuotaUsage",
                "kind": "LinkedField",
                "name": "workspaces",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NonConsumableQuotas",
            "kind": "LinkedField",
            "name": "nonConsumableQuotas",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodesPerContent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodesPerDefinition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "audiencesPerExperience",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dynamicAttributesPerContent",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkspaceContext",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkspaceConsumableQuotas",
                "kind": "LinkedField",
                "name": "consumableQuotas",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuotaUsage",
                    "kind": "LinkedField",
                    "name": "applications",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuotaUsage",
                    "kind": "LinkedField",
                    "name": "audiences",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuotaUsage",
                    "kind": "LinkedField",
                    "name": "components",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuotaUsage",
                    "kind": "LinkedField",
                    "name": "experiences",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuotaUsage",
                    "kind": "LinkedField",
                    "name": "experiments",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuotaUsage",
                    "kind": "LinkedField",
                    "name": "locales",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuotaUsage",
                    "kind": "LinkedField",
                    "name": "slots",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ActiveUsageCount",
                "kind": "LinkedField",
                "name": "experienceCount",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ActiveUsageCount",
                "kind": "LinkedField",
                "name": "experimentCount",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExperienceContext",
                "kind": "LinkedField",
                "name": "experience",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "audienceCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentExperimentName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentExperimentStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExperimentContext",
                    "kind": "LinkedField",
                    "name": "experiment",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "variantCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ApplicationContext",
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSlotContextQuery",
    "selections": (v10/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSlotContextQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "ea4e3463e912a85bdd7cf76e6179df89",
    "id": null,
    "metadata": {},
    "name": "useSlotContextQuery",
    "operationKind": "query",
    "text": "query useSlotContextQuery(\n  $payload: ContextOverviewPayload!\n) {\n  contextOverview(payload: $payload) {\n    user {\n      userId\n      name\n      username\n      organizationCount\n      accountCreationTime\n      expertise\n    }\n    organization {\n      name\n      slug\n      role\n      creationDate\n      membershipCount\n      plan\n      features\n      consumableQuotas {\n        monthlyActiveUsers {\n          total\n          used\n        }\n        workspaces {\n          total\n          used\n        }\n      }\n      nonConsumableQuotas {\n        nodesPerContent\n        nodesPerDefinition\n        audiencesPerExperience\n        dynamicAttributesPerContent\n      }\n      workspace {\n        name\n        slug\n        role\n        creationDate\n        membershipCount\n        consumableQuotas {\n          applications {\n            total\n            used\n          }\n          audiences {\n            total\n            used\n          }\n          components {\n            total\n            used\n          }\n          experiences {\n            total\n            used\n          }\n          experiments {\n            total\n            used\n          }\n          locales {\n            total\n            used\n          }\n          slots {\n            total\n            used\n          }\n        }\n        experienceCount {\n          total\n          active\n        }\n        experimentCount {\n          total\n          active\n        }\n        experience {\n          name\n          status\n          audienceCount\n          currentExperimentName\n          currentExperimentStatus\n          experiment {\n            name\n            status\n            variantCount\n          }\n        }\n        application {\n          name\n          creationDate\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "59d799f7d52bc38e7db6b62a521df0bf";

export default node;
