import {useLocalStorage} from 'react-use';

type DismissedNotificationsState = {
    value: string[],
    add: (notificationId: string) => void,
    has: (notificationId: string) => boolean,
};

export function useDismissedNotificationStorage(): DismissedNotificationsState {
    const [
        dismissedNotifications = [],
        setDismissedNotifications,
    ] = useLocalStorage<string[]>('dismissed_notifications', []);

    return {
        value: dismissedNotifications,
        has: (notification: string): boolean => dismissedNotifications.includes(notification),
        add: (notification: string): void => (
            setDismissedNotifications([...dismissedNotifications, notification])
        ),
    };
}
