import {ReactElement, FunctionComponent} from 'react';
import {
    Outlet,
    RouteObject,
    createBrowserRouter,
    LazyRouteFunction,
    ScrollRestoration,
    matchPath,
} from 'react-router-dom';
import {NotificationContainer} from '@croct-tech/application-ui/components/Notification/NotificationContainer';
import {IEnvironment} from 'relay-runtime';
import {fetchQuery} from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import {AccountPermission} from '../acl';
import {Can} from '../components/Can';
import {Navigate} from '../components/Navigate';
import {PersonalizationProvider} from '../components/PersonalizationProvider';
import {ScopeProvider} from '../components/ScopeProvider';
import {applicationPaths, ApplicationRoutesParams} from './modules/application/routes';
import {authenticationPaths, authenticationRoutes, AuthenticationRoutesParams} from './modules/authentication/routes';
import {organizationPaths, organizationRoutes, OrganizationRoutesParams} from './modules/organization/routes';
import {contentPaths, ContentRoutesParams} from './modules/content/routes';
import {personalizationPaths, PersonalizationRoutesParams} from './modules/personalization/routes';
import {userPaths, userRoutes, UserRoutesParams} from './modules/user/routes';
import {workspacePaths, WorkspaceRoutesParams} from './modules/workspace/routes';
import {RouteErrorBoundary} from '../components/ErrorBoundary';
import {AdminNotificationBar} from './components/AdminNotificationBar';
import {GoalCompletedTracker} from './components/GoalCompletedTracker';
import {useRelay} from '../hooks/useRelay';
import {useGlobalTransition} from '../hooks/useGlobalTransition';
import {RouterProvider, redirect} from '../components/RouterProvider';
import {TopLoadingBar} from '../components/TopLoadingBar';
import {GlobalTransitionProvider} from '../components/GlobalTransitionProvider';
import {lazyImport} from '../utils/lazyImport';
import {onboardingRoutes, onboardingPaths, OnboardingRoutesParams} from './modules/onboarding/routes';
import {createEnvironment} from '../relay';
import {routesQuery, OnboardingStatus} from './__generated__/routesQuery.graphql';
import {GlobalTracker} from './components/GlobalTracker';

const {ErrorPage} = lazyImport(() => import('./pages/ErrorPage'), 'ErrorPage');
const {Header} = lazyImport(() => import('./components/Header'), 'Header');

const BASE_PATH = process.env.REACT_APP_BASE_PATH ?? '';

declare module '../components/RouterProvider' {
    export interface Routes extends RoutesParams {
    }
}

export type RoutesParams =
    ApplicationRoutesParams &
    AuthenticationRoutesParams &
    OrganizationRoutesParams &
    OnboardingRoutesParams &
    UserRoutesParams &
    WorkspaceRoutesParams &
    ContentRoutesParams &
    PersonalizationRoutesParams & {
    'access.denied': never,
    'account.deleted': never,
    'legal.privacy': never,
    'legal.termsOfUse': never,
    'legal.termsOfService': never,
    'not.found': never,
};

export const sitemap: {[key in keyof RoutesParams]: string} = {
    'access.denied': '/',
    'account.deleted': '/good-bye',
    'legal.privacy': '/legal/privacy',
    'legal.termsOfUse': '/legal/terms-of-use',
    'legal.termsOfService': '/legal/terms-of-service',
    'not.found': '/404',
    ...applicationPaths,
    ...authenticationPaths,
    ...organizationPaths,
    ...onboardingPaths,
    ...userPaths,
    ...workspacePaths,
    ...contentPaths,
    ...personalizationPaths,
};

type ErrorRouterPageProps = {
    onRetry: () => void,
};

const RetryablePage: FunctionComponent<ErrorRouterPageProps> = ({onRetry}): ReactElement => {
    const relay = useRelay();
    const {startTransition} = useGlobalTransition();

    return (
        <ErrorPage
            retry={(): void => {
                startTransition(() => {
                    // The cause of the error may be cached in the Relay store,
                    // so reset it to get a fresh start.
                    relay.reset();

                    onRetry();
                });
            }}
        />
    );
};

export const routes: RouteObject[] = [
    {
        element: (
            <GlobalTransitionProvider>
                <TopLoadingBar />
                <NotificationContainer />
                <RouteErrorBoundary fallback={(_, retry): ReactElement => <RetryablePage onRetry={retry} />}>
                    <RouterProvider sitemap={sitemap}>
                        <ScopeProvider>
                            <PersonalizationProvider>
                                <GoalCompletedTracker />
                                <GlobalTracker />
                                <AdminNotificationBar />
                                <Outlet />
                                <ScrollRestoration />
                            </PersonalizationProvider>
                        </ScopeProvider>
                    </RouterProvider>
                </RouteErrorBoundary>
            </GlobalTransitionProvider>
        ),
        children: [
            {
                path: '/good-bye',
                lazy: loadGoodbyePage(),
            },

            /* Admin Routes */
            {
                element: (
                    <Can
                        noCache
                        sustain
                        perform={AccountPermission.SIGN_IN}
                        yes={<Navigate to="account.signIn" />}
                        no={<Outlet />}
                    />
                ),
                loader: async ({request}): Promise<Response | null> => {
                    const environment = createEnvironment();
                    const onboardingStatus = await getOnboardingStatus(environment);
                    const {pathname} = new URL(request.url);

                    const match = matchPath(
                        {
                            path: BASE_PATH + onboardingPaths.onboarding,
                            end: false,
                        },
                        pathname,
                    );

                    if (
                        match === null
                        && (onboardingStatus === 'UNINITIALIZED' || onboardingStatus === 'INITIATED')
                    ) {
                        return redirect('onboarding', sitemap);
                    }

                    return null;
                },
                children: [
                    ...onboardingRoutes,
                    {
                        children: userRoutes,
                        lazy: loadUserLayout(),
                    },
                    {
                        children: organizationRoutes,
                        lazy: loadOrganizationLayout(),
                    },
                ],
            },

            /* Auth Routes */
            {
                element: <Outlet />,
                children: authenticationRoutes,
            },

            /* Not found */
            {
                path: '*',
                lazy: loadNotFoundPage(),
            },

            /* Legal pages */
            {
                path: '/legal/privacy',
                lazy: loadPrivacyPolicyPage(),
            },
            {
                path: '/legal/terms-of-use',
                lazy: loadUseTermsPage(),
            },
            {
                path: '/legal/terms-of-service',
                lazy: loadServiceTermsPage(),
            },

        ],
    },
];

function loadServiceTermsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {AuthLayout} = await import('./layouts/AuthLayout');
        const {ServiceTermsPage} = await import('./pages/ServiceTermsPage');

        return {
            element: (
                <AuthLayout variant="fluid" header={<Header />}>
                    <ServiceTermsPage />
                </AuthLayout>
            ),
        };
    };
}

function loadUseTermsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {AuthLayout} = await import('./layouts/AuthLayout');
        const {UseTermsPage} = await import('./pages/UseTermsPage');

        return {
            element: (
                <AuthLayout variant="fluid" header={<Header />}>
                    <UseTermsPage />
                </AuthLayout>
            ),
        };
    };
}

function loadPrivacyPolicyPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {AuthLayout} = await import('./layouts/AuthLayout');
        const {PrivacyPolicyPage} = await import('./pages/PrivacyPolicyPage');

        return {
            element: (
                <AuthLayout variant="fluid" header={<Header />}>
                    <PrivacyPolicyPage />
                </AuthLayout>
            ),
        };
    };
}

function loadNotFoundPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {AuthLayout} = await import('./layouts/AuthLayout');
        const {StackedLayout} = await import('./layouts/StackedLayout');
        const {NotFoundPage} = await import('./pages/NotFoundPage');

        return {
            element: (
                <Can
                    noCache
                    sustain
                    perform={AccountPermission.SIGN_IN}
                    yes={(
                        <AuthLayout variant="fluid" header={<Header />}>
                            <NotFoundPage />
                        </AuthLayout>
                    )}
                    no={(
                        <StackedLayout>
                            <NotFoundPage />
                        </StackedLayout>
                    )}
                />
            ),
        };
    };
}

function loadGoodbyePage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {AuthLayout} = await import('./layouts/AuthLayout');
        const {HistoryStatePage} = await import('./components/HistoryStatePage');
        const {OffboardingPage} = await import('./pages/OffboardingPage');

        return {
            element: (
                <AuthLayout variant="fluid" header={<Header />}>
                    <HistoryStatePage stateKey="offboarding">
                        <OffboardingPage />
                    </HistoryStatePage>
                </AuthLayout>
            ),
        };
    };
}

function loadUserLayout(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {StackedLayout} = await import('./layouts/StackedLayout');

        return {
            element: (
                <StackedLayout contrastBackground>
                    <Outlet />
                </StackedLayout>
            ),
        };
    };
}

function loadOrganizationLayout(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {StackedLayout} = await import('./layouts/StackedLayout');
        const {ResourceNotFoundBoundary} = await import('../components/ErrorBoundary');

        return {
            element: (
                <StackedLayout>
                    <ResourceNotFoundBoundary>
                        <Outlet />
                    </ResourceNotFoundBoundary>
                </StackedLayout>
            ),
        };
    };
}

export const router = createBrowserRouter(
    routes,
    {
        basename: BASE_PATH,
    },
);

function getOnboardingStatus(environment: IEnvironment): Promise<OnboardingStatus> {
    return fetchQuery<routesQuery>(
        environment,
        graphql`
            query routesQuery {
                userAccount {
                    onboardingStatus
                }
            }
        `,
        {},
    )
        .toPromise()
        .then(result => result?.userAccount.onboardingStatus ?? 'ABSENT')
        .catch(() => 'ABSENT');
}
