/**
 * @generated SignedSource<<480a9d8a74819d86a4a2ab5306a144c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScopeProviderDefinedFragment$data = {
  readonly organization: {
    readonly id: string;
    readonly workspace: {
      readonly application: {
        readonly id: string;
      } | null;
      readonly id: string;
    } | null;
  } | null;
  readonly viewer: {
    readonly user: {
      readonly email: string;
      readonly id: string;
    } | null;
  };
  readonly " $fragmentType": "ScopeProviderDefinedFragment";
};
export type ScopeProviderDefinedFragment$key = {
  readonly " $data"?: ScopeProviderDefinedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScopeProviderDefinedFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "applicationSlug"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationSlug"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "workspaceSlug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScopeProviderDefinedFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Viewer",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "organizationSlug"
        }
      ],
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "slug",
              "variableName": "workspaceSlug"
            }
          ],
          "concreteType": "Workspace",
          "kind": "LinkedField",
          "name": "workspace",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "slug",
                  "variableName": "applicationSlug"
                }
              ],
              "concreteType": null,
              "kind": "LinkedField",
              "name": "application",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "9a0cb4b6079a46efd6f56a7358575423";

export default node;
