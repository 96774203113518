import {Fragment} from 'react';
import {RouteObject, LazyRouteFunction, Outlet} from 'react-router-dom';
import {ApplicationPermission} from '../../../acl';
import {Can} from '../../../components/Can';
import {lazyImport} from '../../../utils/lazyImport';

const {ApplicationBreadcrumb} = lazyImport(
    () => import('./components/ApplicationBreadcrumb'),
    'ApplicationBreadcrumb',
);

type Route =
    'application.dashboard'
    | 'application.dashboard.events'
    | 'application.dashboard.marketing'
    | 'application.settings'
    | 'application.integration'
    | 'application.apiKeys'
    | 'application.home';

export type ApplicationRoutesParams = Record<Route, {
    organizationSlug: string,
    workspaceSlug: string,
    applicationSlug: string,
}>;

const basePath = '/organizations/:organizationSlug/workspaces/:workspaceSlug/applications/:applicationSlug' as const;

export const applicationPaths: {[key in keyof ApplicationRoutesParams]: string} = {
    'application.home': `${basePath}/`,
    'application.dashboard.events': `${basePath}/dashboard/events`,
    'application.dashboard': `${basePath}/dashboard`,
    'application.dashboard.marketing': `${basePath}/dashboard/marketing`,
    'application.settings': `${basePath}/settings`,
    'application.apiKeys': `${basePath}/keys`,
    'application.integration': `${basePath}/integration`,
};

export const applicationRoutes: RouteObject[] = [
    {
        path: basePath,
        lazy: loadLayout(),
        handle: {
            breadcrumb: ApplicationBreadcrumb,
        },
        children: [
            {
                index: true,
                lazy: loadIndexPage(),
            },
            {
                path: applicationPaths['application.dashboard.events'],
                lazy: loadEventsDashboardPage(),
            },
            {
                path: applicationPaths['application.dashboard.marketing'],
                lazy: loadMarketingDashboardPage(),
            },
            {
                path: applicationPaths['application.dashboard'],
                lazy: loadGeneralDashboardPage(),
            },
            {
                path: applicationPaths['application.settings'],
                lazy: loadSettingsPage(),
            },
            {
                path: applicationPaths['application.apiKeys'],
                lazy: loadApiKeysPage(),
            },
            {
                path: applicationPaths['application.integration'],
                lazy: loadIntegrationPage(),
            },
        ],
    },
];

function loadIntegrationPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {IntegrationPage} = await import('./pages/IntegrationPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ApplicationPermission.VIEW}
                    yes={<IntegrationPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadApiKeysPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ApiKeysPage} = await import('./pages/ApiKeysPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ApplicationPermission.VIEW_SETTINGS}
                    yes={<ApiKeysPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadSettingsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {SettingsPage} = await import('./pages/SettingsPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ApplicationPermission.VIEW_SETTINGS}
                    yes={<SettingsPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadGeneralDashboardPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {GeneralDashboardPage} = await import('./pages/GeneralDashboardPage');

        return {
            element: (
                <GeneralDashboardPage />
            ),
        };
    };
}

function loadMarketingDashboardPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {MarketingDashboardPage} = await import('./pages/MarketingDashboardPage');

        return {
            element: (
                <MarketingDashboardPage />
            ),
        };
    };
}

function loadEventsDashboardPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {EventsDashboardPage} = await import('./pages/EventsDashboardPage');

        return {
            element: (
                <EventsDashboardPage />
            ),
        };
    };
}

function loadLayout(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {NotFoundPage} = await import('../../pages/NotFoundPage');
        const {ApplicationMenu} = await import('./components/ApplicationMenu');

        return {
            element: (
                <Can
                    noCache
                    perform={ApplicationPermission.VIEW}
                    no={<NotFoundPage />}
                    yes={(
                        <Fragment>
                            <ApplicationMenu />
                            <Outlet />
                        </Fragment>
                    )}
                />
            ),
        };
    };
}

function loadIndexPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {NotFoundPage} = await import('../../pages/NotFoundPage');

        return {
            element: <NotFoundPage />,
        };
    };
}
