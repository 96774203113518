import {useContext} from 'react';
import {ScopeContext, ScopeState} from '../components/ScopeProvider';

export function useScope(): ScopeState {
    const context = useContext(ScopeContext);

    if (context == null) {
        throw new Error('useScope must be used within a <ScopeProvider />');
    }

    return context;
}
