import {Outlet, RouteObject, LazyRouteFunction} from 'react-router-dom';
import {AccountPermission} from '../../../acl';
import {Can} from '../../../components/Can';
import {Navigate} from '../../../components/Navigate';
import {Header} from '../../components/Header';

export type AuthenticationRoutesParams = {
    'account.signUp': never,
    'account.signIn': never,
    'account.signOut': never,
    'account.welcome': never,
    'account.resetPassword': never,
    'account.forgotPassword': never,
    'account.resetPasswordPending': never,
};

export const authenticationPaths: {[key in keyof AuthenticationRoutesParams]: string} = {
    'account.signUp': '/signup',
    'account.signIn': '/signin',
    'account.signOut': '/signout',
    'account.welcome': '/welcome',
    'account.resetPassword': '/reset-password',
    'account.forgotPassword': '/forgot-password',
    'account.resetPasswordPending': '/reset-link-sent',
};

export const authenticationRoutes: RouteObject[] = [
    {
        element: (
            <Can
                noCache
                sustain
                perform={AccountPermission.SIGN_IN}
                yes={<Outlet />}
                no={<Navigate to="account.home" />}
            />
        ),
        children: [
            {
                lazy: loadLayout(),
                children: [
                    {
                        path: authenticationPaths['account.forgotPassword'],
                        lazy: loadForgotPasswordPage(),
                    },
                    {
                        path: authenticationPaths['account.resetPasswordPending'],
                        lazy: loadResetPasswordPendingPage(),
                    },
                    {
                        path: authenticationPaths['account.signIn'],
                        lazy: loadSignInPage(),
                    },
                    {
                        path: authenticationPaths['account.signUp'],
                        lazy: loadSignUpPage(),
                    },
                    {
                        path: authenticationPaths['account.welcome'],
                        lazy: loadWelcomePage(),
                    },
                ],
            },
        ],
    },
    {
        path: authenticationPaths['account.resetPassword'],
        lazy: loadResetPasswordPage(),
    },
];

function loadResetPasswordPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ResetPasswordPage} = await import('./pages/ResetPasswordPage');
        const {AuthLayout} = await import('../../layouts/AuthLayout');

        return {
            element: (
                <AuthLayout header={<Header />}>
                    <ResetPasswordPage />
                </AuthLayout>
            ),
        };
    };
}

function loadWelcomePage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {WelcomePage} = await import('./pages/WelcomePage');
        const {HistoryStatePage} = await import('../../components/HistoryStatePage');

        return {
            element: (
                <HistoryStatePage stateKey="email">
                    <WelcomePage />
                </HistoryStatePage>
            ),
        };
    };
}

function loadSignInPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {SignInPage} = await import('./pages/SignInPage');

        return {
            element: (
                <SignInPage />
            ),
        };
    };
}

function loadResetPasswordPendingPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ResetPasswordPendingPage} = await import('./pages/ResetPasswordPendingPage');
        const {HistoryStatePage} = await import('../../components/HistoryStatePage');

        return {
            element: (
                <HistoryStatePage stateKey="email">
                    <ResetPasswordPendingPage />
                </HistoryStatePage>
            ),
        };
    };
}

function loadForgotPasswordPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ForgotPasswordPage} = await import('./pages/ForgotPasswordPage');

        return {
            element: (
                <ForgotPasswordPage />
            ),
        };
    };
}

function loadSignUpPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {SignUpPage} = await import('./pages/SignUpPage');

        return {
            element: (
                <SignUpPage />
            ),
        };
    };
}

function loadLayout(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {AuthLayout} = await import('../../layouts/AuthLayout');

        return {
            element: (
                <AuthLayout header={<Header />}>
                    <Outlet />
                </AuthLayout>
            ),
        };
    };
}
