import {createContext, FunctionComponent, ReactNode, useMemo, useTransition} from 'react';

export type GlobalTransitionState = {
    isPending: boolean,
    startTransition: (callback: () => void) => void,
};

export const GlobalTransitionContext = createContext<GlobalTransitionState>({
    isPending: false,
    startTransition: () => undefined,
});

type GlobalTransitionProviderProps = {
    children: ReactNode,
};

export const GlobalTransitionProvider: FunctionComponent<GlobalTransitionProviderProps> = ({children}) => {
    const [isPending, startTransition] = useTransition();

    const state: GlobalTransitionState = useMemo(
        () => ({
            isPending: isPending,
            startTransition: startTransition,
        }),
        [isPending, startTransition],
    );

    return (
        <GlobalTransitionContext.Provider value={state}>
            {children}
        </GlobalTransitionContext.Provider>
    );
};
