import {ReactElement} from 'react';
import {Box} from '@croct-tech/application-ui/components/Box';
import {Button} from '@croct-tech/application-ui/components/Button';
import {Heading} from '@croct-tech/application-ui/components/Heading';
import {ArrowClockwiseCircleIcon} from '@croct-tech/application-ui/components/Icons/Outline/ArrowClockwiseCircleIcon';
import {Trans} from '@croct-tech/application-ui/components/Trans';
import {useTranslation} from '@croct-tech/application-ui/hooks/useTranslation';
import {Illustration} from './Illustration';
import {style} from './styles';
import {translationKey} from './translations';
import {SupportLink, ContactSubject} from '../../components/SupportLink';

type ErrorBoundaryProps = {
    retry: () => void,
};

export function ErrorPage({retry}: ErrorBoundaryProps): ReactElement {
    const {t} = useTranslation(translationKey);

    return (
        <Box style={style}>
            <Illustration />
            <div className="title">
                <Heading lineHeight="tight" level={1} appearance={2} value={t('title')} />
            </div>
            <p className="subtitle">
                <Trans i18nKey={`${translationKey}.subtitle` as const}>
                    <SupportLink
                        underlined
                        support={{
                            subject: ContactSubject.BUG_REPORT,
                        }}
                    />
                </Trans>
            </p>
            <Button
                size="md"
                onClick={retry}
                icon={ArrowClockwiseCircleIcon}
                variant="secondary"
                appearance="important"
                iconPlacement="prefix"
                label={t('action.retry')}
                width={{_: 'full', sm: 'auto'}}
            />
        </Box>
    );
}
