import {BoxStyle} from '@croct-tech/application-ui/components/Box';
import {responsive} from '@croct-tech/application-ui/styles/responsiveness';

export const style: BoxStyle = {
    borderBottomColor: 'admin.component.header.border',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    width: '100%',

    '& > nav': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        height: 14,
        margin: '0 auto',
        maxWidth: '2xl',
        paddingX: responsive({_: 6, md: 16, lg: 10}),

        '& > .logo': {
            display: 'inline-flex',
        },
    },
};
