import {FunctionComponent, ReactElement} from 'react';
import {Box} from '@croct-tech/application-ui/components/Box';
import {Logo} from '@croct-tech/application-ui/components/Logo';
import {RouteLink} from '../../../components/RouteLink';
import {style} from './styles';

export type HeaderProps = {
    children?: ReactElement,
    disableLogoLink?: boolean,
};

export const Header: FunctionComponent<HeaderProps> = ({children, disableLogoLink}) => (
    <Box as="header" style={style}>
        <nav>
            {disableLogoLink === true
                ? <Logo height={5} />
                : (
                    <RouteLink to="account.home" className="logo">
                        <Logo height={5} />
                    </RouteLink>
                )}
            {children}
        </nav>
    </Box>
);
