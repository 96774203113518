import {useContext} from 'react';
import {RouterContext, RouterState} from '../components/RouterProvider';

export function useRouter(): RouterState {
    const context = useContext(RouterContext);

    if (context == null) {
        throw new Error('Hook "useRouter" must be used within an Router');
    }

    return context;
}
