import {FunctionComponent} from 'react';
import {Link, LinkProps} from '@croct-tech/application-ui/components/Link';
import {useTranslation} from '@croct-tech/application-ui/hooks/useTranslation';
import {
    ContactSubject,
    SupportLinkGeneratorProps,
    useSupportLinkGenerator,
} from '../../../hooks/useSupportLinkGenerator';
import {SupportLinkTranslations, translationKey} from './translations';

export {ContactSubject} from '../../../hooks/useSupportLinkGenerator';

export enum Quota {
    MAU = 'mau'
}

type Resource = Quota;

type NonResourceRelatedLinkProps = Pick<SupportLinkGeneratorProps, 'message'> & {
    subject: Exclude<ContactSubject, ContactSubject.LIMIT_INCREASE>,
};

type ResourceRelatedLinkProps<T extends ContactSubject = ContactSubject, R extends Resource = Resource> = {
    subject: T,
    resource: R,
};

type SupportLinkProps = LinkProps & {
    support: NonResourceRelatedLinkProps
        | ResourceRelatedLinkProps<ContactSubject.LIMIT_INCREASE, Quota>,
};

export const SupportLink: FunctionComponent<SupportLinkProps> = props => {
    const {support, ...linkProps} = props;

    const {t} = useTranslation(translationKey);
    const {getSupportLink} = useSupportLinkGenerator();

    return (
        <Link
            {...linkProps}
            underlined
            target="_blank"
            href={
                getSupportLink({
                    subject: support.subject,
                    message: isNonResourceRelated(props.support)
                        ? props.support.message
                        : t(`resource.${resourceTranslations[props.support.resource]}`),
                })
            }
        />
    );
};

function isNonResourceRelated(support: SupportLinkProps['support']): support is NonResourceRelatedLinkProps {
    switch (support.subject) {
        case ContactSubject.LIMIT_INCREASE:
            return false;

        default:
            return true;
    }
}

type TranslationKey = keyof SupportLinkTranslations['resource'];

const resourceTranslations: Record<Resource, TranslationKey> = {
    mau: 'mauLimitIncrease',
};
