import {createContext, FunctionComponent, ReactNode, useCallback, useMemo} from 'react';
import {createPath as createRouterPath, Path, Pathname, State} from 'history';
import {
    generatePath,
    resolvePath as resolveRouterPath,
    useNavigate as useDefaultNavigate,
    redirect as routerRedirect,
    createSearchParams,
    URLSearchParamsInit,
} from 'react-router-dom';
import {useGlobalTransition} from '../../hooks/useGlobalTransition';

export interface Routes {
}

export type RouteId = keyof Routes extends never ? string : keyof Routes;

type ParametrizedRouteId = {
    [K in keyof Routes]: Routes[K] extends never
        ? never
        : K
}[keyof Routes];

type ParametrizedPath<R extends RouteId> = {
    path: R,
    params: RouteParams<R>,
    search?: URLSearchParamsInit,
};

type NonParametrizedPath<R extends RouteId> = Omit<ParametrizedPath<R>, 'params'>;

type RouteParams<R extends RouteId> = R extends keyof Routes ? Routes[R] : Record<string, string>;

type TargetPath<R extends Pathname | RouteId> = R extends RouteId
    ? (R extends ParametrizedRouteId ? ParametrizedPath<R> : (NonParametrizedPath<R> | R))
    : {pathname: R, search?: URLSearchParamsInit};

export type To = TargetPath<Pathname> | TargetPath<RouteId>;

export type NavigateOptions = {
    replace?: boolean,
    state?: State,
};

export interface RouterState {
    navigate(delta: number): void;
    navigate(to: To, options?: NavigateOptions): void;
    createPath(to: To): string;
}

type RouterProviderProps = {
    children: ReactNode,
    sitemap: Record<keyof Routes, string>,
};

function isParametrizedPath(to: To): to is ParametrizedPath<any> {
    return typeof to === 'object' && 'path' in to;
}

function isPathname(to: To): to is {pathname: Pathname, search?: URLSearchParamsInit} {
    return typeof to === 'object' && 'pathname' in to;
}

export const RouterContext = createContext<RouterState | undefined>(undefined);

export const RouterProvider: FunctionComponent<RouterProviderProps> = ({children, sitemap}) => {
    const navigate = useDefaultNavigate();
    const {startTransition} = useGlobalTransition();

    const resolveRoutePath = useCallback(
        (to: To): Path => resolvePath(to, sitemap),
        [sitemap],
    );

    const state: RouterState = useMemo(
        () => ({
            navigate: (to: number | To, options?: NavigateOptions): void => {
                startTransition(() => {
                    if (typeof to === 'number') {
                        navigate(to);

                        return;
                    }

                    navigate(resolveRoutePath(to), options);
                });
            },
            createPath: (to: To): string => createPath(to, sitemap),
        }),
        [startTransition, navigate, resolveRoutePath, sitemap],
    );

    return (
        <RouterContext.Provider value={state}>
            {children}
        </RouterContext.Provider>
    );
};

export function redirect(to: To, sitemap: RouterProviderProps['sitemap']): Response {
    return routerRedirect(createPath(to, sitemap));
}

function resolvePath(to: To, sitemap: RouterProviderProps['sitemap']): Path {
    if (typeof to === 'string') {
        return resolveRouterPath(sitemap[to]);
    }

    const path = resolveRouterPath(normalizePath(to, sitemap));

    if ('search' in to && to.search !== undefined) {
        path.search = `?${createSearchParams(to.search).toString()}`;
    }

    return path;
}

function createPath(to: To, sitemap: RouterProviderProps['sitemap']): string {
    return createRouterPath(resolvePath(to, sitemap));
}

function normalizePath(to: To, sitemap: RouterProviderProps['sitemap']): string {
    if (isParametrizedPath(to)) {
        return generatePath(sitemap[to.path], to.params);
    }

    if (isPathname(to)) {
        return to.pathname;
    }

    return generatePath(sitemap[(to as NonParametrizedPath<RouteId>).path]);
}
