import {FunctionComponent, ReactNode} from 'react';
import {Box} from '@croct-tech/application-ui/components/Box';
import {Helmet as Head} from 'react-helmet-async';
import {style} from './styles';

export type PageProps = {
    title: string,
    children?: ReactNode,
};

export const Page: FunctionComponent<PageProps> = ({children, title}) => (
    <Box as="main" style={style}>
        <Head>
            <title>{title}</title>
        </Head>
        {children}
    </Box>
);
