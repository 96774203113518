import {Outlet, RouteObject, LazyRouteFunction} from 'react-router-dom';
import {WorkspaceComponentPermission} from '../../../acl';
import {Can} from '../../../components/Can';
import {lazyImport} from '../../../utils/lazyImport';

const {ComponentsBreadcrumb} = lazyImport(
    () => import('./components/ContentBreadcrumb'),
    'ComponentsBreadcrumb',
);

type ContentParams = {
    organizationSlug: string,
    workspaceSlug: string,
};

export type ContentRoutesParams = {
    'content.components': ContentParams & {
        // Allow opening union components via URL.
        customId?: string,
    },
    'content.components.new': ContentParams,
    'content.components.edit': ContentParams & {
        customId: string,
    },
};

const workspaceBasePath = '/organizations/:organizationSlug/workspaces/:workspaceSlug';

export const contentPaths: {[key in keyof ContentRoutesParams]: string} = {
    'content.components': `${workspaceBasePath}/components`,
    'content.components.new': `${workspaceBasePath}/components/new`,
    'content.components.edit': `${workspaceBasePath}/components/edit/:customId`,
};

export const contentRoutes: RouteObject[] = [
    {
        path: contentPaths['content.components'],
        element: <Outlet />,
        handle: {
            breadcrumb: ComponentsBreadcrumb,
            hideOnExactMatch: true,
        },
        children: [
            {
                index: true,
                lazy: loadComponentsPage(),
            },
            {
                path: contentPaths['content.components.new'],
                lazy: loadNewComponentPage(),
            },
            {
                path: contentPaths['content.components.edit'],
                lazy: loadEditComponentPage(),
            },
        ],
    },
];

function loadComponentsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ComponentsPage} = await import('./pages/ComponentsPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceComponentPermission.VIEW}
                    yes={<ComponentsPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadNewComponentPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {NewRegularComponentPage} = await import('./pages/NewRegularComponentPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceComponentPermission.CREATE}
                    yes={<NewRegularComponentPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadEditComponentPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {RegularComponentEditPage} = await import('./pages/RegularComponentEditPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceComponentPermission.VIEW}
                    yes={<RegularComponentEditPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}
