import './polyfill';
import {FunctionComponent, ReactElement} from 'react';
import {Locale} from '@croct-tech/application-ui/components/LocaleProvider';
import {UiProvider} from '@croct-tech/application-ui/UiProvider';
import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import {HelmetProvider} from 'react-helmet-async';
import {RouterProvider} from 'react-router-dom';
import {register} from 'timeago.js';
import enUsLang from 'timeago.js/lib/lang/en_US';
import {ErrorBoundary} from './components/ErrorBoundary';
import {RelayProvider} from './hooks/useRelay';
import {createEnvironment} from './relay';
import {ErrorPage} from './screens/pages/ErrorPage';
import {router} from './screens/routes';
import {lightMode} from './styles/lightMode';
import {loadTranslations} from './translations';

register('en', enUsLang);
const relayEnvironment = createEnvironment();

function getBrowserTimeZone(): string | undefined {
    const {timeZone} = Intl.DateTimeFormat().resolvedOptions();

    if ((timeZone ?? 'etc/unknown').toLowerCase() === 'etc/unknown') {
        return undefined;
    }

    return timeZone;
}

const defaultLocale: Locale = {
    code: 'en-us',
    timeZone: getBrowserTimeZone() ?? 'UTC',
};

const i18nInstance = i18next.createInstance({
    ns: ['ui', 'admin', 'country', 'subdivision', 'timezone', 'locale', 'calendar'],
    lng: defaultLocale.code,
    fallbackLng: defaultLocale.code,
    supportedLngs: ['en-us'],
    lowerCaseLng: true,
    load: 'currentOnly',
    debug: false,
    returnNull: false,
    interpolation: {
        escapeValue: false,
    },
});

i18nInstance.use(
    resourcesToBackend((language, namespace, callback) => {
        loadTranslations(language, namespace).then(resources => {
            callback(null, resources);
        })
            .catch(error => {
                callback(error, null);
            });
    }),
);

i18nInstance.init();

export const App: FunctionComponent = () => (
    <HelmetProvider>
        <UiProvider
            colorModes={{light: lightMode}}
            defaultMode="light"
            locale={defaultLocale}
            i18n={i18nInstance}
        >
            <ErrorBoundary fallback={(_, retry): ReactElement => <ErrorPage retry={retry} />}>
                <RelayProvider environment={relayEnvironment}>
                    <RouterProvider future={{v7_startTransition: true}} router={router} />
                </RelayProvider>
            </ErrorBoundary>
        </UiProvider>
    </HelmetProvider>
);
