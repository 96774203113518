import {FunctionComponent} from 'react';

export const Illustration: FunctionComponent = () => (
    <svg
        width="279"
        height="192"
        viewBox="0 0 279 192"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M45.017 146.982L57.1235 135.046V157.383L45.017 169.489V146.982Z"
            fill="#DDE2E7"
        />
        <path
            d="M45.0159 169.488L57.1224 157.382V180.06L45.0159 191.996V169.488Z"
            fill="#DDE2E7"
        />
        <path
            d="M11.936 135.043H36.3195L24.554 146.979H0L11.936 135.043Z"
            fill="#EAEEF1"
        />
        <path
            d="M36.3181 135.044H57.1208L45.0143 146.98H24.5526L36.3181 135.044Z"
            fill="#EAEEF1"
        />
        <path
            d="M90.0297 169.488L102.136 157.382V180.06L90.0297 191.996V169.488Z"
            fill="#DDE2E7"
        />
        <path
            d="M56.9508 135.043H131.5V146.979H45.0148L56.9508 135.043Z"
            fill="#EAEEF1"
        />
        <path
            d="M130.956 146.981L143.063 135.045V157.383L130.956 169.489V146.981Z"
            fill="#DDE2E7"
        />
        <path
            d="M130.954 169.49L143.06 157.384V180.062L130.954 191.998V169.49Z"
            fill="#DDE2E7"
        />
        <path
            d="M45.0167 101.964L57.1232 90.0276V135L47.5 147.5L45.0167 101.964Z"
            fill="#DDE2E7"
        />
        <path
            d="M11.936 90.0275L59 91.5L48 101.963H0L11.936 90.0275Z"
            fill="#EAEEF1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48 102H0V192H45H48H131V147H48V102Z"
            fill="#CED3DB"
        />
        <path
            d="M175.969 146.982L188.076 135.046V157.383L175.969 169.489V146.982Z"
            fill="#EAEEF1"
        />
        <path
            d="M175.968 169.488L188.074 157.382V180.06L175.968 191.996V169.488Z"
            fill="#EAEEF1"
        />
        <path
            d="M142.888 135.047H167.272L155.506 146.983H130.952L142.888 135.047Z"
            fill="#F6F7F8"
        />
        <path
            d="M167.27 135.045H188.073L175.966 146.981H155.505L167.27 135.045Z"
            fill="#F6F7F8"
        />
        <path
            d="M175.97 101.962L188.076 90.0258V112.363L175.97 124.47V101.962Z"
            fill="#EAEEF1"
        />
        <path
            d="M175.966 124.466L188.072 112.36V135.038L175.966 146.974V124.466Z"
            fill="#EAEEF1"
        />
        <path
            d="M142.887 90.0255H167.27L155.505 101.962H130.951L142.887 90.0255Z"
            fill="#F6F7F8"
        />
        <path
            d="M167.269 90.0256H188.072L175.966 101.962H155.504L167.269 90.0256Z"
            fill="#F6F7F8"
        />
        <path
            d="M131 102H221V192H131V102Z"
            fill="#B3B8C1"
        />
        <path
            d="M220.985 146.983L233.091 135.047V157.385L220.985 169.491V146.983Z"
            fill="#EAEEF1"
        />
        <path
            d="M220.985 169.49L233.091 157.384V180.062L220.985 191.998V169.49Z"
            fill="#EAEEF1"
        />
        <path
            d="M220.984 101.962L233.091 90.026V112.363L220.984 124.47V101.962Z"
            fill="#EAEEF1"
        />
        <path
            d="M220.984 124.467L233.091 112.36V135.039L220.984 146.975V124.467Z"
            fill="#EAEEF1"
        />
        <path
            d="M187.906 90.0258H212.29L200.525 101.962H175.97L187.906 90.0258Z"
            fill="#F6F7F8"
        />
        <path
            d="M212.289 90.0259H233.092L220.986 101.962H200.524L212.289 90.0259Z"
            fill="#F6F7F8"
        />
        <path
            d="M89.3495 56.9538L101.456 45.0178V67.3552L89.3495 79.4617V56.9538Z"
            fill="#FF8896"
        />
        <path
            d="M89.348 79.4645L101.455 67.358V90.0364L89.348 101.972V79.4645Z"
            fill="#FF8896"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FF8896"
            d="M80.6535 45.0138H56.27L44.334 56.9498H68.8855L68.8854 56.9499H89.3471L101.454
            45.0139H80.6534L80.6535 45.0138Z"
        />
        <path
            d="M101.287 45.0138H125.67L113.905 56.9498H89.3508L101.287 45.0138Z"
            fill="#FF8896"
        />
        <path
            d="M125.668 45.0139H146.471L134.364 56.9499H113.902L125.668 45.0139Z"
            fill="#FF8896"
        />
        <path
            d="M179.381 56.9538L191.487 45.0178V67.3552L179.381 79.4617V56.9538Z"
            fill="#FF8896"
        />
        <path
            d="M179.379 79.4645L191.485 67.358V90.0364L179.379 101.972V79.4645Z"
            fill="#FF8896"
        />
        <path
            d="M146.235 45.0769H187.937L175.937 57.0129H133.197L146.235 45.0769Z"
            fill="#FF8896"
        />
        <path
            d="M133.367 11.936L145.473 0V22.3374L133.367 34.4439V11.936Z"
            fill="#FD687A"
        />
        <path
            d="M133.367 34.4446L145.473 22.3381V45.0165L133.367 56.9525V34.4446Z"
            fill="#FD687A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M124.56 0H100.831L89 12H113H113.338H134L145.5 0H125H124.56Z"
            fill="#FF8896"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M134 12H89V57H44V102H176V57H134V12Z"
            fill="#FA596C"
        />
        <path
            d="M266 56.4999L278.107 45V180.06L266 191.996V56.4999Z"
            fill="#DDE2E7"
        />
        <path
            d="M232.922 135.05H257.305L245.54 146.986H220.986L232.922 135.05Z"
            fill="#E9EDF1"
        />
        <path
            d="M232.921 90.0315H257.304L245.539 101.967H220.985L232.921 90.0315Z"
            fill="#E9EDF1"
        />
        <path
            d="M220.986 56.95L233.093 45.014V67.3514L220.986 79.4579V56.95Z"
            fill="#DDE2E7"
        />
        <path
            d="M220.985 79.4606L233.091 67.3541V90.0325L220.985 101.969V79.4606Z"
            fill="#DDE2E7"
        />
        <path
            d="M187.908 45.0138H278.105L266 57H176L187.908 45.0138Z"
            fill="#E9EDF1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M266 57H176V102H221V192H266V102V57Z"
            fill="#CED3DB"
        />
        <g clipPath="url(#errorBoundary)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FEA0AB"
                d="M110.289 49.9983C111.057 48.6679 112.977 48.6679 113.745 49.9983L131.461
                80.6818C132.229 82.0122 131.268 83.6753 129.732 83.6753H94.302C92.7657 83.6753
                91.8056 82.0122 92.5737 80.6818L110.289 49.9983ZM110.066
                70.3338H113.954V60.6115H110.066V70.3338ZM113.964
                72.2798H110.076V76.1679H113.964V72.2798Z"
            />
        </g>
        <defs>
            <clipPath id="errorBoundary">
                <rect
                    width="39.9135"
                    height="39.9135"
                    fill="white"
                    transform="translate(92.0603 46.5059)"
                />
            </clipPath>
        </defs>
    </svg>
);
