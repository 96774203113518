import {TopLoadingBar as LoadingBar} from '@croct-tech/application-ui/components/TopLoadingBar';
import {Portal} from '@ariakit/react/portal';
import {FunctionComponent} from 'react';
import {useNavigation} from 'react-router';
import {useGlobalTransition} from '../../hooks/useGlobalTransition';

export const TopLoadingBar: FunctionComponent = () => {
    const {isPending} = useGlobalTransition();
    const {state, location} = useNavigation();

    return (
        <Portal>
            <LoadingBar
                key={location?.key}
                id="top-loading-bar"
                loading={isPending || state === 'loading'}
                duration={10_000}
                delay={100}
            />
        </Portal>
    );
};
