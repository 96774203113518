import {RouteObject, LazyRouteFunction} from 'react-router-dom';

export type UserRoutesParams = {
    'account.home': never,
    'account.home.newOrganization': never,
    'account.settings': never,
};

export const userPaths: {[key in keyof UserRoutesParams]: string} = {
    'account.home': '/',
    'account.home.newOrganization': '/new-organization',
    'account.settings': '/settings',
};

export const userRoutes: RouteObject[] = [
    {
        path: userPaths['account.home'],
        lazy: loadHomePage(false),
    },
    {
        path: userPaths['account.home.newOrganization'],
        lazy: loadHomePage(true),
    },
    {
        path: userPaths['account.settings'],
        lazy: loadSettingsPage(),
    },
];

function loadSettingsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {SettingsPage} = await import('./pages/SettingsPage');

        return {
            element: (
                <SettingsPage />
            ),
        };
    };
}

function loadHomePage(openCreateOrganizationDrawer: boolean): LazyRouteFunction<RouteObject> {
    return async () => {
        const {HomePage} = await import('./pages/HomePage');

        return {
            element: (
                <HomePage openCreateOrganizationDrawer={openCreateOrganizationDrawer} />
            ),
        };
    };
}
