import {useParams} from 'react-router-dom';
import {WebsiteLink} from '../screens/link';

export enum ContactSubject {
    GENERAL_INQUIRY = 'general-inquiry',
    BUG_REPORT = 'bug-report',
    FEATURE_REQUEST = 'feature-request',
    PLAN_UPGRADE = 'plan-upgrade',
    LIMIT_INCREASE = 'limit-increase',
    BILLING_MATTERS = 'billing-matters',
}

export type SupportLinkGeneratorProps = {
    subject: ContactSubject,
    message?: string,
};

type SupportLinkGenerator = {
    getSupportLink: (props: SupportLinkGeneratorProps) => string,
};

export function useSupportLinkGenerator(): SupportLinkGenerator {
    const {organizationSlug} = useParams();

    return {
        getSupportLink: (props): string => {
            const {subject, message} = props;

            const url = new URL(WebsiteLink.CONTACT_SUPPORT);

            if (organizationSlug !== undefined) {
                url.searchParams.set('organization', organizationSlug);
            }

            if (message !== undefined) {
                url.searchParams.set('message', message);
            }

            url.searchParams.set('subject', subject);

            return url.href;
        },
    };
}
