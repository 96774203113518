import {FunctionComponent} from 'react';

export const Illustration: FunctionComponent = () => (
    <svg
        width="320"
        height="123"
        viewBox="0 0 320 123"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="#CED3DB"
            d="M54.502
            90.9085H92.9862V105.792H111.392V90.9085H120.925V73.8358H111.392V16.1387H87.1493L54.502
            74.0109V90.9085ZM93.3753 73.8358H73.6468V73.1354L92.7527 39.0774H93.3753V73.8358Z"
        />
        <path
            fill="#CED3DB"
            d="M205.717
            90.9085H244.201V105.792H262.606V90.9085H272.14V73.8358H262.606V16.1387H238.364L205.717
            74.0109V90.9085ZM244.59 73.8358H224.862V73.1354L243.968 39.0774H244.59V73.8358Z"
        />
        <path
            d="M19.0068 81.3359L32.1703 89.8435L24.608 104.652L11.4445 96.1449L19.0068 81.3359Z"
            fill="#CED3DB"
        />
        <path
            d="M291.456 94.6016L304.255 102.874L296.902 117.273L284.103 109.001L291.456 94.6016Z"
            fill="#CED3DB"
        />
        <path
            d="M10.2725 23.5293L14.2026 26.0693L11.9448 30.4907L8.01464 27.9507L10.2725 23.5293Z"
            fill="#DCE0E5"
        />
        <path
            d="M291.208 58.4336L295.049 60.9159L292.842 65.2368L289.002 62.7545L291.208 58.4336Z"
            fill="#DCE0E5"
        />
        <path
            fill="#DCE0E5"
            d="M303.939 71.8626C300.085 73.7638 298.33 78.8187 300.02 83.1545L313.976 76.271C312.288
            71.9361 307.794 69.9613 303.939 71.8626Z"
        />
        <path
            fill="#DCE0E5"
            d="M14.15 47.0041C7.99131 50.0419 5.18822 58.1185 7.8882 65.0463L30.1858 54.0479C27.4892
            47.1216 20.3086 43.9663 14.15 47.0041Z"
        />
        <path
            d="M307.533 32.3632L308.817 55.5454L290.33 45.2057L307.533 32.3632Z"
            fill="#EAEEF1"
        />
        <path
            d="M35.1579 10.1341L51.0786 20.6228L35.0441 30.8897L35.1579 10.1341Z"
            fill="#DCE0E5"
        />
        <path
            fill="#AAADFF"
            d="M129.381 66.6551L194.494 77.9897L191.751 99.3861C191.065 104.743 186.697 108.33
            181.96 107.426L142.117 99.8244C138.569 99.1473 135.675 96.0802 134.822 92.0917L129.381
            66.6551Z"
        />
        <path
            fill="#CBCDFE"
            d="M145.806 22.9888C147.273 19.1236 150.757 16.7293 154.484 17.0245L192.488
            20.0352C197.697 20.4479 201.432 25.8596 200.497 31.6389L192.937 78.3633L129.157
            66.8452L145.806 22.9888Z"
        />
        <path
            fill="#8286FC"
            d="M182.511 37.295C184.352 37.2248 185.444 39.5874 184.335 41.2429L172.25
            59.2838C171.513 60.3837 170.118 60.5912 169.152 59.7446L149.935 42.8965C148.319 41.4799
            149.16 38.5658 151.208 38.4878L182.511 37.295Z"
        />
        <path
            fill="#1C1E60"
            d="M174.945 48.563C176.807 48.4539 177.932 50.8439 176.805 52.5151L172.273
            59.2373C171.532 60.336 170.134 60.5376 169.171 59.6847L162.332 53.6289C160.742 52.2209
            161.553 49.3479 163.574 49.2294L174.945 48.563Z"
        />
        <path
            d="M149.844 42.6616L184.128 41.5029L175.999 53.827L162.297 53.5062L149.844 42.6616Z"
            fill="#1C1E60"
        />
        <path
            d="M191.021 102.404L192.39 80.2151L200.061 31.8301L197.321 64.4977L191.021 102.404Z"
            fill="#8286FC"
        />
        <path
            fill="#FC82BD"
            d="M173.396 24.3244C173.396 20.2987 176.257 17.0352 179.787 17.0352H194.398C197.928
            17.0352 200.79 20.2987 200.79 24.3244V41.5215C200.79 48.4228 195.884 54.0173 189.832
            54.0173H184.353C178.301 54.0173 173.396 48.4228 173.396 41.5215V24.3244Z"
        />
        <path
            fill="#FFAAD3"
            d="M200.79 23.1995C200.79 27.9653 194.657 31.8287 187.093 31.8287C179.528 31.8287
            173.396 27.9653 173.396 23.1995C173.396 18.4337 179.528 14.5703 187.093 14.5703C194.657
            14.5703 200.79 18.4337 200.79 23.1995Z"
        />
    </svg>
);
