import graphql from 'babel-plugin-relay/macro';
import {RouteObject, LazyRouteFunction, Outlet} from 'react-router-dom';
import {fetchQuery} from 'react-relay/hooks';
import {IEnvironment} from 'relay-runtime';
import {routesOnboardingQuery, OnboardingStatus} from './__generated__/routesOnboardingQuery.graphql';
import {createEnvironment} from '../../../relay';
import {redirect} from '../../../components/RouterProvider';
import {sitemap} from '../../routes';

export type OnboardingRoutesParams = {
    'onboarding': never,
    'onboarding.useCase': never,
    'onboarding.component': never,
    'onboarding.completed': never,
};

export const onboardingPaths: {[key in keyof OnboardingRoutesParams]: string} = {
    onboarding: '/onboarding',
    'onboarding.useCase': '/onboarding/use-case',
    'onboarding.component': '/onboarding/use-case/component',
    'onboarding.completed': '/onboarding/all-set',
};

export const onboardingRoutes: RouteObject[] = [
    {
        element: <Outlet />,
        loader: async ({request}): Promise<null | Response> => {
            const environment = createEnvironment();
            const onboardingStatus = await getOnboardingStatus(environment);

            if (onboardingStatus === 'BYPASSED') {
                // The search term is preserved when redirecting to the home page.
                // This is useful for notifying the user about the activation, for example.
                const searchTerm = URL.canParse(request.url) ? `${new URL(request.url).searchParams}` : '';

                return redirect(
                    {
                        path: 'account.home',
                        search: searchTerm,
                    },
                    sitemap,
                );
            }

            return null;
        },
        children: [
            {
                path: onboardingPaths.onboarding,
                lazy: loadAccountSetupPage(),
            },
            {
                lazy: loadHistoryLayout(),
                children: [
                    {
                        path: onboardingPaths['onboarding.useCase'],
                        lazy: loadUseCaseSetupPage(),
                    },
                    {
                        path: onboardingPaths['onboarding.component'],
                        lazy: loadComponentSetupPage(),
                    },
                    {
                        path: onboardingPaths['onboarding.completed'],
                        lazy: loadCompletedSetupPage(),
                    },
                ],
            },
        ],
    },
];

function loadHistoryLayout(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {HistoryStatePage} = await import('../../components/HistoryStatePage');
        const {NotFoundPage} = await import('../../pages/NotFoundPage');
        const {AuthLayout} = await import('../../layouts/AuthLayout');
        const {Header} = await import('../../components/Header');

        return {
            element: (
                <HistoryStatePage
                    stateKey="website"
                    fallback={(
                        <AuthLayout variant="fluid" header={<Header />}>
                            <NotFoundPage />
                        </AuthLayout>
                    )}
                >
                    <Outlet />
                </HistoryStatePage>
            ),
        };
    };
}

function loadCompletedSetupPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {CompletedSetupPage} = await import('./pages/CompletedSetupPage');
        const {AuthLayout} = await import('../../layouts/AuthLayout');
        const {Header} = await import('../../components/Header');

        return {
            element: (
                <AuthLayout variant="fluid" header={<Header disableLogoLink />}>
                    <CompletedSetupPage />
                </AuthLayout>
            ),
        };
    };
}

function loadComponentSetupPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ComponentSetupPage} = await import('./pages/ComponentSetupPage');
        const {AuthLayout} = await import('../../layouts/AuthLayout');
        const {OnboardingHeader} = await import('./components/OnboardingHeader');

        return {
            element: (
                <AuthLayout variant="grid" header={<OnboardingHeader />}>
                    <ComponentSetupPage />
                </AuthLayout>
            ),
        };
    };
}

function loadUseCaseSetupPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {UseCaseSetupPage} = await import('./pages/UseCaseSetupPage');
        const {AuthLayout} = await import('../../layouts/AuthLayout');
        const {OnboardingHeader} = await import('./components/OnboardingHeader');

        return {
            element: (
                <AuthLayout variant="grid" header={<OnboardingHeader />}>
                    <UseCaseSetupPage />
                </AuthLayout>
            ),
        };
    };
}

function loadAccountSetupPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {AccountSetupPage} = await import('./pages/AccountSetupPage');
        const {AuthLayout} = await import('../../layouts/AuthLayout');
        const {OnboardingHeader} = await import('./components/OnboardingHeader');

        return {
            element: (
                <AuthLayout variant="grid" header={<OnboardingHeader />}>
                    <AccountSetupPage />
                </AuthLayout>
            ),
        };
    };
}

function getOnboardingStatus(environment: IEnvironment): Promise<OnboardingStatus> {
    return fetchQuery<routesOnboardingQuery>(
        environment,
        graphql`
            query routesOnboardingQuery {
                userAccount {
                    onboardingStatus
                }
            }
        `,
        {},
    )
        .toPromise()
        .then(result => result?.userAccount.onboardingStatus ?? 'ABSENT')
        .catch(() => 'ABSENT');
}
