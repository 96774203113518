import {BoxStyle} from '@croct-tech/application-ui/components/Box';
import {responsive} from '@croct-tech/application-ui/styles/responsiveness';

export const style: BoxStyle = {
    alignItems: 'center',
    backgroundColor: 'admin.pages.errorPage.background',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: responsive({_: 6, sm: 0}),
    textAlign: 'center',
    width: '100%',

    '& > .title': {
        color: 'admin.pages.errorPage.title',
        marginTop: 12,
    },

    '& > .subtitle': {
        color: 'admin.pages.errorPage.subtitle',
        lineHeight: 'normal',
        marginBottom: 6,
        marginTop: 4,
        maxWidth: 'xs',
    },
};
