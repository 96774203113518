import {Fragment} from 'react';
import {RouteObject, LazyRouteFunction, Outlet} from 'react-router-dom';
import {OrganizationMemberPermission, OrganizationPermission} from '../../../acl';
import {Can} from '../../../components/Can';
import {workspaceRoutes} from '../workspace/routes';
import {lazyImport} from '../../../utils/lazyImport';

const {OrganizationBreadcrumb} = lazyImport(
    () => import('./components/OrganizationBreadcrumb'),
    'OrganizationBreadcrumb',
);

type Route = 'organization.members' | 'organization.home' | 'organization.settings';

export type OrganizationRoutesParams = Record<Route, {organizationSlug: string}>;

const basePath = '/organizations/:organizationSlug' as const;

export const organizationPaths: {[key in keyof OrganizationRoutesParams]: string} = {
    'organization.home': `${basePath}/`,
    'organization.members': `${basePath}/members`,
    'organization.settings': `${basePath}/settings`,
};

export const organizationRoutes: RouteObject[] = [
    {
        path: basePath,
        handle: {
            breadcrumb: OrganizationBreadcrumb,
        },
        children: [
            {
                lazy: loadLayout(),
                children: [
                    {
                        path: organizationPaths['organization.home'],
                        lazy: loadWorkspacePage(),
                    },
                    {
                        path: organizationPaths['organization.members'],
                        lazy: loadMembersPage(),
                    },
                    {
                        path: organizationPaths['organization.settings'],
                        lazy: loadSettingsPage(),
                    },
                ],
            },
            ...workspaceRoutes,
        ],
    },
];

function loadWorkspacePage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {WorkspacePage} = await import('./pages/WorkspacePage');

        return {
            element: <WorkspacePage />,
        };
    };
}

function loadMembersPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {MembersPage} = await import('./pages/MembersPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={OrganizationMemberPermission.VIEW}
                    yes={<MembersPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadSettingsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {SettingsPage} = await import('./pages/SettingsPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={OrganizationPermission.VIEW_SETTINGS}
                    yes={<SettingsPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadLayout(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {NotFoundPage} = await import('../../pages/NotFoundPage');
        const {OrganizationMenu} = await import('./components/OrganizationMenu');

        return {
            element: (
                <Can
                    noCache
                    perform={OrganizationPermission.VIEW}
                    no={<NotFoundPage />}
                    yes={(
                        <Fragment>
                            <OrganizationMenu />
                            <Outlet />
                        </Fragment>
                    )}
                />
            ),
        };
    };
}
