import {FunctionComponent, useEffect} from 'react';
import {useSearchParams} from '../../../hooks/useSearchParams';
import {useGoalCompletedTracker} from '../GoalCompletedTracker/useGoalCompletedTracker';

export const GlobalTracker: FunctionComponent = () => {
    const trackGoalCompleted = useGoalCompletedTracker();
    const searchParams = useSearchParams();

    useEffect(
        () => {
            if (searchParams.get('accountActivated') === 'true') {
                trackGoalCompleted('account-activation');
            }
        },
        [searchParams, trackGoalCompleted],
    );

    return null;
};
