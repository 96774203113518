import {Resource} from 'i18next';

export function loadTranslations(language: string, namespace: string): Promise<Resource> {
    switch (namespace) {
        case 'ui':
            return import(`@croct-tech/application-ui/translations/${language}/ui`)
                .then(module => module.default);

        case 'country':
            return import(`@croct-tech/application-ui/translations/${language}/country`)
                .then(module => module.default);

        case 'subdivision':
            return import(`@croct-tech/application-ui/translations/${language}/subdivision`)
                .then(module => module.default);

        case 'timezone':
            return import(`@croct-tech/application-ui/translations/${language}/timezone`)
                .then(module => module.default);

        case 'calendar':
            return import(`@croct-tech/application-ui/translations/${language}/calendar`)
                .then(module => module.default);

        case 'locale':
            return import(`@croct-tech/application-ui/translations/${language}/locale`)
                .then(module => module.default);

        case 'admin':
            return import(`./${language}/admin`).then(module => module.default);

        default:
            return Promise.reject(new Error(`Unknown namespace: ${namespace}`));
    }
}
