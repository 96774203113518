/**
 * @generated SignedSource<<ecfbcba60e24105f379d73a5fda9a537>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ApplicationTrafficStatus = "NEVER_RECEIVED_TRAFFIC" | "NOT_RECEIVING_TRAFFIC" | "RECEIVING_TRAFFIC";
export type AdminNotificationBarQuery$variables = {
  applicationSlug?: string | null;
  organizationSlug?: string | null;
  workspaceSlug?: string | null;
};
export type AdminNotificationBarQuery$data = {
  readonly organization: {
    readonly id: string;
    readonly quotas: {
      readonly monthlyActiveUsers: number;
      readonly remainingMonthlyActiveUsers: number;
    };
    readonly workspace: {
      readonly application: {
        readonly applicationStatus: ApplicationTrafficStatus;
      } | null;
      readonly receivingTraffic: ApplicationTrafficStatus;
    } | null;
  } | null;
};
export type AdminNotificationBarQuery$rawResponse = {
  readonly organization: {
    readonly id: string;
    readonly quotas: {
      readonly monthlyActiveUsers: number;
      readonly remainingMonthlyActiveUsers: number;
    };
    readonly workspace: {
      readonly application: {
        readonly __typename: string;
        readonly applicationStatus: ApplicationTrafficStatus;
        readonly id: string;
      } | null;
      readonly id: string;
      readonly receivingTraffic: ApplicationTrafficStatus;
    } | null;
  } | null;
};
export type AdminNotificationBarQuery = {
  rawResponse: AdminNotificationBarQuery$rawResponse;
  response: AdminNotificationBarQuery$data;
  variables: AdminNotificationBarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "applicationSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceSlug"
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationQuotas",
  "kind": "LinkedField",
  "name": "quotas",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingMonthlyActiveUsers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyActiveUsers",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "workspaceSlug"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivingTraffic",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "applicationSlug"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "applicationStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminNotificationBarQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "RequiredField",
            "field": (v5/*: any*/),
            "action": "LOG",
            "path": "organization.quotas"
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdminNotificationBarQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c2442de2f62dfcd894604d7a52ae2fc",
    "id": null,
    "metadata": {},
    "name": "AdminNotificationBarQuery",
    "operationKind": "query",
    "text": "query AdminNotificationBarQuery(\n  $organizationSlug: ReadableId\n  $workspaceSlug: ReadableId\n  $applicationSlug: ReadableId\n) {\n  organization(slug: $organizationSlug) {\n    id\n    quotas {\n      remainingMonthlyActiveUsers\n      monthlyActiveUsers\n    }\n    workspace(slug: $workspaceSlug) {\n      receivingTraffic\n      application(slug: $applicationSlug) {\n        __typename\n        applicationStatus\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cd8d9a14ae2397b2ee1446ee20b4233";

export default node;
