import {useLocale} from '@croct-tech/application-ui/hooks/useLocale';
import {useCroct} from '@croct/plug-react';
import {EventListener} from '@croct/sdk/tracker';
import {UserProfile} from '@croct/sdk/trackingEvents';
import {FunctionComponent, useCallback, useEffect} from 'react';
import {useScope} from '../../../hooks/useScope';

type SubscribePayload = {
    tags: string[],
    email: string,
    source?: string,
    firstName?: string,
    lastName?: string,
    phone?: string,
    custom?: UserProfile['custom'],
};

const CONTACT_ENDPOINT = process.env.REACT_APP_CROCT_CONTACT_ENDPOINT_URL!;

export const GoalCompletedTracker: FunctionComponent = () => {
    const croct = useCroct();
    const {locale: {code}} = useLocale();
    const {user: {email: userEmail}} = useScope();

    const updateContact = useCallback(
        ({email, tags, firstName = '', lastName = '', phone, custom, source}: SubscribePayload): void => {
            const name = `${firstName} ${lastName}`.trim();

            fetch(CONTACT_ENDPOINT, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    tags: tags,
                    email: email,
                    source: source,
                    language: code,
                    phone: phone,
                    ...(name !== '' ? {name: name} : {}),
                    ...(custom !== undefined ? {extra: custom} : {}),
                    page: {
                        url: window.location.href,
                        title: document.title,
                        ...(document.referrer !== '' ? {referrer: document.referrer} : {}),
                    },
                }),
            });
        },
        [code],
    );

    useEffect(
        () => {
            const listener: EventListener = ({event, status}): void => {
                if (status !== 'confirmed') {
                    return;
                }

                switch (event.type) {
                    case 'goalCompleted':
                        if (window.dataLayer !== undefined) {
                            window.dataLayer.push({
                                event: event.goalId,
                                ...(userEmail !== null
                                    ? {user_properties: {email: userEmail}}
                                    : {}
                                ),
                            });
                        }

                        if (userEmail !== null && event.goalId !== 'sign-up') {
                            updateContact({
                                email: userEmail,
                                tags: [event.goalId],
                            });
                        }

                        break;

                    case 'userSignedUp':
                        if (event.profile?.email !== undefined) {
                            updateContact({
                                tags: ['sign-up'],
                                source: 'admin',
                                email: event.profile.email,
                                firstName: event.profile.firstName,
                                lastName: event.profile.lastName,
                                phone: event.profile.phone,
                                custom: event.profile.custom,
                            });

                            if (window.dataLayer !== undefined) {
                                window.dataLayer.push({
                                    event: 'sign-up',
                                    user_properties: {
                                        email: event.profile.email,
                                        ...(event.profile.phone !== undefined
                                            ? {phone: event.profile.phone}
                                            : {}
                                        ),
                                        ...(event.profile.custom?.expertise !== undefined
                                            ? {expertise: event.profile.custom.expertise}
                                            : {}
                                        ),
                                    },
                                });
                            }
                        }

                        break;
                }
            };

            croct.tracker.addListener(listener);

            return () => {
                croct.tracker.removeListener(listener);
            };
        },
        [croct, updateContact, userEmail],
    );

    return null;
};
