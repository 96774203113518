import {FunctionComponent, useState, createContext, useMemo, useContext, ReactNode} from 'react';
import {RelayEnvironmentProvider} from 'react-relay/hooks';
import {IEnvironment} from 'relay-runtime';
import {createEnvironment} from '../relay';

interface RelayState {
    reset(): void;
}

const RelayContext = createContext<RelayState|undefined>(undefined);

type RelayProviderProps = {
    environment?: IEnvironment,
    children?: ReactNode,
};

export const RelayProvider: FunctionComponent<RelayProviderProps> = props => {
    const {children, environment = createEnvironment()} = props;
    const [currentEnvironment, setEnvironment] = useState(environment);

    const state: RelayState = useMemo(
        () => ({
            reset: (): void => setEnvironment(createEnvironment()),
        }),
        [],
    );

    return (
        <RelayContext.Provider value={state}>
            <RelayEnvironmentProvider environment={currentEnvironment}>
                {children}
            </RelayEnvironmentProvider>
        </RelayContext.Provider>
    );
};

export function useRelay(): RelayState {
    const context = useContext(RelayContext);

    if (context == null) {
        throw new Error('Hook "useRelay" must be used within an RelayProvider');
    }

    return context;
}
