import {createContext, FunctionComponent, ReactNode, useMemo} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useFragment, useLazyLoadQuery} from 'react-relay';
import {useParams} from 'react-router-dom';
import {ScopeProviderQuery} from './__generated__/ScopeProviderQuery.graphql';
import {
    ScopeProviderDefinedFragment$key as OrganizationRef,
} from './__generated__/ScopeProviderDefinedFragment.graphql';
import {ScopeProviderNullFragment$key as UserIdRef} from './__generated__/ScopeProviderNullFragment.graphql';

export type ScopeState = {
    user: {
        id: string | null,
        email: string | null,
    },
    organizationId?: string | null,
    workspaceId?: string | null,
    applicationId?: string | null,
};

export const ScopeContext = createContext<ScopeState | null>(null);

type NullScopeProviderProps = {
    queryRef: UserIdRef,
    children?: ReactNode,
};

const NullScopeProvider: FunctionComponent<NullScopeProviderProps> = props => {
    const {children, queryRef} = props;

    const {viewer} = useFragment(
        graphql`
            fragment ScopeProviderNullFragment on Query {
                viewer {
                    user {
                        id
                        email
                    }
                }
            }
        `,
        queryRef,
    );

    const {user} = viewer;

    const scopeState: ScopeState = useMemo(
        () => ({
            organizationId: null,
            workspaceId: null,
            applicationId: null,
            user: {
                id: user?.id ?? null,
                email: user?.email ?? null,
            },
        }),
        [user?.email, user?.id],
    );

    return (
        <ScopeContext.Provider value={scopeState}>
            {children}
        </ScopeContext.Provider>
    );
};

type DefinedScopeProviderProps = {
    queryRef: OrganizationRef,
    params: {
        organizationSlug: string,
        workspaceSlug?: string,
        applicationSlug?: string,
    },
    children?: ReactNode,
};

const DefinedScopeProvider: FunctionComponent<DefinedScopeProviderProps> = props => {
    const {children, params, queryRef} = props;
    const {organizationSlug, workspaceSlug, applicationSlug} = params;

    const {organization, viewer} = useFragment(
        graphql`
            fragment ScopeProviderDefinedFragment on Query
            @argumentDefinitions(
                organizationSlug: {
                    type: "ReadableId",
                },
                workspaceSlug: {
                    type: "ReadableId",
                },
                applicationSlug: {
                    type: "ReadableId",
                },
            ) {
                viewer {
                    user {
                        id
                        email
                    }
                }
                organization(slug: $organizationSlug) {
                    id
                    workspace(slug: $workspaceSlug) {
                        id
                        application(slug: $applicationSlug) {
                            id
                        }
                    }
                }
            }
        `,
        queryRef,
    );

    const {user} = viewer;

    const scopeState: ScopeState = useMemo(
        () => {
            const organizationId = organization?.id ?? undefined;
            const workspaceId = organization?.workspace?.id ?? undefined;
            const applicationId = organization?.workspace?.application?.id ?? undefined;

            return {
                organizationId: organizationSlug === undefined ? null : organizationId,
                workspaceId: workspaceSlug === undefined ? null : workspaceId,
                applicationId: applicationSlug === undefined ? null : applicationId,
                user: {
                    id: user?.id ?? null,
                    email: user?.email ?? null,
                },
            };
        },
        [
            applicationSlug,
            organization?.id,
            organization?.workspace?.application?.id,
            organization?.workspace?.id,
            organizationSlug,
            user?.email,
            user?.id,
            workspaceSlug,
        ],
    );

    return (
        <ScopeContext.Provider value={scopeState}>
            {children}
        </ScopeContext.Provider>
    );
};

type ScopeProviderProps = {
    children: ReactNode,
};

export const ScopeProvider: FunctionComponent<ScopeProviderProps> = ({children}) => {
    const params = useParams();
    const {organizationSlug} = params;

    const data = useLazyLoadQuery<ScopeProviderQuery>(
        graphql`
            query ScopeProviderQuery(
                $organizationSlug: ReadableId,
                $workspaceSlug: ReadableId,
                $applicationSlug: ReadableId,
            ) {
                ...ScopeProviderNullFragment
                ...ScopeProviderDefinedFragment @arguments(
                    organizationSlug: $organizationSlug,
                    workspaceSlug: $workspaceSlug,
                    applicationSlug: $applicationSlug,
                )
            }
        `,
        {
            ...params,
            organizationSlug: organizationSlug,
        },
    );

    return organizationSlug !== undefined
        ? (
            <DefinedScopeProvider
                queryRef={data}
                params={{
                    ...params,
                    organizationSlug: organizationSlug,
                }}
            >
                {children}
            </DefinedScopeProvider>
        )
        : (
            <NullScopeProvider queryRef={data}>
                {children}
            </NullScopeProvider>
        );
};
