import {ReactNode, Component, FunctionComponent, ReactElement} from 'react';
import {useLocation} from 'react-router-dom';
import {NotFoundPage} from '../../screens/pages/NotFoundPage';

type ErrorBoundaryProps = {
    fallback: (error: Error, retry: () => void) => ReactNode,
    catches?: (error: Error) => boolean,
    children: ReactNode,
};

type State = {
    error: Error | null,
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
    public constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {error: null};
    }

    public static getDerivedStateFromError(error: Error): State {
        return {error: error};
    }

    public componentDidCatch(error: Error): void {
        if (this.props.catches !== undefined && !this.props.catches(error)) {
            throw error;
        }
    }

    public render(): ReactNode {
        const {error} = this.state;

        const {children, fallback} = this.props;

        if (error != null) {
            return (fallback(error, () => this.setState({error: null})));
        }

        return children;
    }
}

type ResourceNotFoundBoundaryProps = Omit<ErrorBoundaryProps, 'catches' | 'fallback'>;

const MESSAGE_PATTERN = /Relay: Missing @required value at path '.+?' in '.+ResourceQuery'\./i;

export const ResourceNotFoundBoundary: FunctionComponent<ResourceNotFoundBoundaryProps> = props => {
    const {children, ...rest} = props;

    return (
        <ErrorBoundary
            {...rest}
            fallback={(): ReactElement => <NotFoundPage />}
            catches={(error: any): boolean => error instanceof Error && MESSAGE_PATTERN.test(error.message)}
        >
            {children}
        </ErrorBoundary>
    );
};

export const RouteErrorBoundary: FunctionComponent<ErrorBoundaryProps> = props => {
    const {key} = useLocation();

    return <ErrorBoundary key={key} {...props} />;
};
