import {Fragment} from 'react';
import {Outlet, RouteObject, LazyRouteFunction, LoaderFunctionArgs} from 'react-router-dom';
import {ApplicationPermission, WorkspaceMemberPermission, WorkspacePermission} from '../../../acl';
import {Can} from '../../../components/Can';
import {applicationRoutes} from '../application/routes';
import {contentRoutes} from '../content/routes';
import {personalizationRoutes} from '../personalization/routes';
import {lazyImport} from '../../../utils/lazyImport';
import {Expertise} from '../user/expertise';
import {sitemap} from '../../routes';
import {redirect} from '../../../components/RouterProvider';

const {WorkspaceBreadcrumb} = lazyImport(
    () => import('./components/WorkspaceBreadcrumb'),
    'WorkspaceBreadcrumb',
);

const {ApplicationsBreadcrumb} = lazyImport(
    () => import('./components/WorkspaceBreadcrumb'),
    'ApplicationsBreadcrumb',
);

type Route =
    | 'workspace.applications'
    | 'workspace.applications.newApplication'
    | 'workspace.home'
    | 'workspace.members'
    | 'workspace.settings'
    | 'workspace.integration';

export type WorkspaceRoutesParams = Record<Route, {
    organizationSlug: string,
    workspaceSlug: string,
}>;

const basePath = '/organizations/:organizationSlug/workspaces/:workspaceSlug' as const;

export const workspacePaths: {[key in keyof WorkspaceRoutesParams]: string} = {
    'workspace.applications': `${basePath}/applications`,
    'workspace.applications.newApplication': `${basePath}/applications/new-application`,
    'workspace.home': `${basePath}/`,
    'workspace.members': `${basePath}/members`,
    'workspace.settings': `${basePath}/settings`,
    'workspace.integration': `${basePath}/integration`,
};

export const workspaceRoutes: RouteObject[] = [
    {
        path: basePath,
        handle: {
            breadcrumb: WorkspaceBreadcrumb,
        },
        children: [
            {
                lazy: loadLayout(),
                children: [
                    {
                        path: workspacePaths['workspace.home'],
                        lazy: loadDashboardPage(),
                    },
                    {
                        path: workspacePaths['workspace.members'],
                        lazy: loadMembersPage(),
                    },
                    {
                        path: workspacePaths['workspace.settings'],
                        lazy: loadSettingsPage(),
                    },
                    {
                        path: workspacePaths['workspace.applications'],
                        lazy: loadApplicationsPage(false),
                    },
                    {
                        path: workspacePaths['workspace.applications.newApplication'],
                        lazy: loadApplicationsPage(true),
                    },
                    ...contentRoutes,
                    ...personalizationRoutes,
                ],
            },
            {
                path: workspacePaths['workspace.integration'],
                lazy: loadIntegrationPage(),
                loader: handleIntegrationPageRedirect,
            },
            {
                path: workspacePaths['workspace.applications'],
                element: <Outlet />,
                handle: {
                    breadcrumb: ApplicationsBreadcrumb,
                },
                children: applicationRoutes,
            },
        ],
    },
];

function handleIntegrationPageRedirect({request, params}: LoaderFunctionArgs): Response | null {
    const url = new URL(request.url);
    const expertise = url.searchParams
        .get('expertise')
        ?.toLowerCase() ?? null;

    if (
        // It is present in the URL
        expertise !== null
        // It is a valid expertise
        && Expertise.isValid(expertise)
        // It is not engineering
        && expertise !== Expertise.ENGINEERING
    ) {
        return redirect(
            {
                path: 'workspace.home',
                params: params as WorkspaceRoutesParams['workspace.integration'],
            },
            sitemap,
        );
    }

    return null;
}

function loadApplicationsPage(newApplication: boolean): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ApplicationsPage} = await import('./pages/ApplicationsPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ApplicationPermission.LIST}
                    yes={<ApplicationsPage newApplicationDrawerInitiallyOpen={newApplication} />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadIntegrationPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {IntegrationPage} = await import('./pages/IntegrationPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceMemberPermission.VIEW}
                    yes={<IntegrationPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadSettingsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {SettingsPage} = await import('./pages/SettingsPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspacePermission.VIEW_SETTINGS}
                    yes={<SettingsPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadMembersPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {MembersPage} = await import('./pages/MembersPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceMemberPermission.VIEW}
                    yes={<MembersPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadDashboardPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {DashboardPage} = await import('./pages/DashboardPage');

        return {
            element: <DashboardPage />,
        };
    };
}

function loadLayout(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {NotFoundPage} = await import('../../pages/NotFoundPage');
        const {WorkspaceMenu} = await import('./components/WorkspaceMenu');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspacePermission.VIEW}
                    no={<NotFoundPage />}
                    yes={(
                        <Fragment>
                            <WorkspaceMenu />
                            <Outlet />
                        </Fragment>
                    )}
                />
            ),
        };
    };
}
