import {Outlet, RouteObject, LazyRouteFunction} from 'react-router-dom';
import {
    ExperiencePermission,
    ExperimentPermission,
    WorkspaceAudiencePermission,
    WorkspaceSlotPermission,
} from '../../../acl';
import {Can} from '../../../components/Can';
import {lazyImport} from '../../../utils/lazyImport';

const {AudiencesBreadcrumb} = lazyImport(
    () => import('./components/PersonalizationBreadcrumb'),
    'AudiencesBreadcrumb',
);

const {ExperienceBreadcrumb} = lazyImport(
    () => import('./components/PersonalizationBreadcrumb'),
    'ExperienceBreadcrumb',
);

const {ExperiencesBreadcrumb} = lazyImport(
    () => import('./components/PersonalizationBreadcrumb'),
    'ExperiencesBreadcrumb',
);

const {ExperimentsBreadcrumb} = lazyImport(
    () => import('./components/PersonalizationBreadcrumb'),
    'ExperimentsBreadcrumb',
);

const {SlotsBreadcrumb} = lazyImport(
    () => import('./components/PersonalizationBreadcrumb'),
    'SlotsBreadcrumb',
);

type PersonalizationParams = {
    organizationSlug: string,
    workspaceSlug: string,
};

type ExperienceParams = PersonalizationParams & {
    experienceId: string,
};

type ExperimentParams = ExperienceParams & {
    experimentId: string,
};

export type PersonalizationRoutesParams = {
    'personalization.audiences': PersonalizationParams,
    'personalization.audiences.new': PersonalizationParams,
    'personalization.audiences.edit': PersonalizationParams & {audienceCustomId: string},
    'personalization.slots': PersonalizationParams,
    'personalization.slots.new': PersonalizationParams,
    'personalization.slots.edit': PersonalizationParams & {
        slotCustomId: string,
        version: string,
    },
    'personalization.experiences': PersonalizationParams,
    'personalization.experiences.dashboard': ExperienceParams,
    'personalization.experiences.edit': ExperienceParams,
    'personalization.experiences.editPublished': ExperienceParams,
    'personalization.experiences.experiments': ExperienceParams,
    'personalization.experiences.experiments.edit': ExperimentParams,
    'personalization.experiences.experiments.dashboard': ExperimentParams,
};

const workspaceBasePath = '/organizations/:organizationSlug/workspaces/:workspaceSlug';
const experienceBasePath = `${workspaceBasePath}/experiences/:experienceId`;
const experimentBasePath = `${experienceBasePath}/experiments/:experimentId`;

export const personalizationPaths: {[key in keyof PersonalizationRoutesParams]: string} = {
    'personalization.audiences': `${workspaceBasePath}/audiences`,
    'personalization.audiences.new': `${workspaceBasePath}/audiences/new`,
    'personalization.audiences.edit': `${workspaceBasePath}/audiences/edit/:audienceCustomId`,
    'personalization.slots': `${workspaceBasePath}/slots`,
    'personalization.slots.new': `${workspaceBasePath}/slots/new`,
    'personalization.slots.edit': `${workspaceBasePath}/slots/edit/:slotCustomId/:version`,
    'personalization.experiences': `${workspaceBasePath}/experiences`,
    'personalization.experiences.edit': `${experienceBasePath}/edit`,
    'personalization.experiences.editPublished': `${experienceBasePath}/edit/published`,
    'personalization.experiences.dashboard': `${experienceBasePath}/dashboard`,
    'personalization.experiences.experiments': `${experienceBasePath}/experiments`,
    'personalization.experiences.experiments.edit': `${experimentBasePath}/edit`,
    'personalization.experiences.experiments.dashboard': `${experimentBasePath}/dashboard`,
};

export const personalizationRoutes: RouteObject[] = [
    {
        path: personalizationPaths['personalization.audiences'],
        element: <Outlet />,
        handle: {
            breadcrumb: AudiencesBreadcrumb,
            hideOnExactMatch: true,
        },
        children: [
            {
                index: true,
                lazy: loadAudiencesPage(),
            },
            {
                path: personalizationPaths['personalization.audiences.new'],
                lazy: loadNewAudiencePage(),
            },
            {
                path: personalizationPaths['personalization.audiences.edit'],
                lazy: loadEditAudiencePage(),
            },
        ],
    },
    {
        path: personalizationPaths['personalization.slots'],
        element: <Outlet />,
        handle: {
            breadcrumb: SlotsBreadcrumb,
            hideOnExactMatch: true,
        },
        children: [
            {
                index: true,
                lazy: loadSlotsPage(),
            },
            {
                path: personalizationPaths['personalization.slots.new'],
                lazy: loadNewSlotPage(),
            },
            {
                path: personalizationPaths['personalization.slots.edit'],
                lazy: loadEditSlotPage(),
            },
        ],
    },
    {
        path: personalizationPaths['personalization.experiences'],
        element: <Outlet />,
        handle: {
            breadcrumb: ExperiencesBreadcrumb,
            hideOnExactMatch: true,
        },
        children: [
            {
                index: true,
                lazy: loadExperiencesPage(),
            },
            {
                path: personalizationPaths['personalization.experiences.edit'],
                lazy: loadExperienceSettingsPage(),
            },
            {
                path: personalizationPaths['personalization.experiences.editPublished'],
                lazy: loadExperienceSettingsPage(),
            },
            {
                path: personalizationPaths['personalization.experiences.dashboard'],
                lazy: loadExperienceOverviewPage(),
            },
            {
                path: personalizationPaths['personalization.experiences.experiments'],
                handle: {
                    breadcrumb: ExperienceBreadcrumb,
                    hideOnExactMatch: true,
                },
                children: [
                    {
                        element: <Outlet />,
                        handle: {
                            breadcrumb: ExperimentsBreadcrumb,
                            hideOnExactMatch: true,
                        },
                        children: [
                            {
                                index: true,
                                lazy: loadExperimentsPage(),
                            },
                            {
                                path: personalizationPaths['personalization.experiences.experiments.dashboard'],
                                lazy: loadExperimentOverviewPage(),
                            },
                            {
                                path: personalizationPaths['personalization.experiences.experiments.edit'],
                                lazy: loadExperimentSettingsPage(),
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

function loadExperimentOverviewPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ExperimentOverviewPage} = await import('./pages/ExperimentOverviewPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ExperimentPermission.VIEW}
                    yes={<ExperimentOverviewPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadExperimentSettingsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ExperimentSettingsPage} = await import('./pages/ExperimentSettingsPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ExperimentPermission.VIEW}
                    yes={<ExperimentSettingsPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadExperimentsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ExperimentsPage} = await import('./pages/ExperimentsPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ExperimentPermission.VIEW}
                    yes={<ExperimentsPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadExperienceOverviewPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ExperienceOverviewPage} = await import('./pages/ExperienceOverviewPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ExperiencePermission.VIEW}
                    yes={<ExperienceOverviewPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadExperienceSettingsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ExperienceSettingsPage} = await import('./pages/ExperienceSettingsPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ExperiencePermission.VIEW}
                    yes={<ExperienceSettingsPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadExperiencesPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {ExperiencesPage} = await import('./pages/ExperiencesPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={ExperiencePermission.VIEW}
                    yes={<ExperiencesPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadEditSlotPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {EditSlotPage} = await import('./pages/EditSlotPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceSlotPermission.VIEW}
                    yes={<EditSlotPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadNewSlotPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {NewSlotPage} = await import('./pages/NewSlotPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceSlotPermission.CREATE}
                    yes={<NewSlotPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadSlotsPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {SlotsPage} = await import('./pages/SlotsPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceSlotPermission.VIEW}
                    yes={<SlotsPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadNewAudiencePage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {NewAudiencePage} = await import('./pages/NewAudiencePage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceAudiencePermission.CREATE}
                    yes={<NewAudiencePage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadEditAudiencePage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {EditAudiencePage} = await import('./pages/EditAudiencePage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceAudiencePermission.VIEW}
                    yes={<EditAudiencePage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}

function loadAudiencesPage(): LazyRouteFunction<RouteObject> {
    return async () => {
        const {AudiencesPage} = await import('./pages/AudiencesPage');
        const {RestrictedAccessPage} = await import('../../pages/RestrictedAccessPage');

        return {
            element: (
                <Can
                    noCache
                    perform={WorkspaceAudiencePermission.VIEW}
                    yes={<AudiencesPage />}
                    no={<RestrictedAccessPage />}
                />
            ),
        };
    };
}
