/**
 * @generated SignedSource<<4fd0eb54b135dd5a3a3559948e44f11d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScopeProviderQuery$variables = {
  applicationSlug?: string | null;
  organizationSlug?: string | null;
  workspaceSlug?: string | null;
};
export type ScopeProviderQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ScopeProviderDefinedFragment" | "ScopeProviderNullFragment">;
};
export type ScopeProviderQuery = {
  response: ScopeProviderQuery$data;
  variables: ScopeProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "applicationSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceSlug"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScopeProviderQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ScopeProviderNullFragment"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "applicationSlug",
            "variableName": "applicationSlug"
          },
          {
            "kind": "Variable",
            "name": "organizationSlug",
            "variableName": "organizationSlug"
          },
          {
            "kind": "Variable",
            "name": "workspaceSlug",
            "variableName": "workspaceSlug"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ScopeProviderDefinedFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ScopeProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "organizationSlug"
          }
        ],
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "slug",
                "variableName": "workspaceSlug"
              }
            ],
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "slug",
                    "variableName": "applicationSlug"
                  }
                ],
                "concreteType": null,
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72e467fc6b262baf1117aafa7b5700de",
    "id": null,
    "metadata": {},
    "name": "ScopeProviderQuery",
    "operationKind": "query",
    "text": "query ScopeProviderQuery(\n  $organizationSlug: ReadableId\n  $workspaceSlug: ReadableId\n  $applicationSlug: ReadableId\n) {\n  ...ScopeProviderNullFragment\n  ...ScopeProviderDefinedFragment_3MRgsN\n}\n\nfragment ScopeProviderDefinedFragment_3MRgsN on Query {\n  viewer {\n    user {\n      id\n      email\n    }\n    id\n  }\n  organization(slug: $organizationSlug) {\n    id\n    workspace(slug: $workspaceSlug) {\n      id\n      application(slug: $applicationSlug) {\n        __typename\n        id\n      }\n    }\n  }\n}\n\nfragment ScopeProviderNullFragment on Query {\n  viewer {\n    user {\n      id\n      email\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7dcaa1aaeb4d51857064912c9c88408f";

export default node;
