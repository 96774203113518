import {useMemo, useState} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useFragment, useLazyLoadQuery} from 'react-relay/hooks';
import {useParams} from 'react-router-dom';
import {Permissions} from '../acl';
import {usePermissionQuery} from './__generated__/usePermissionQuery.graphql';
import {usePermissionFragment$key as PermissionRef} from './__generated__/usePermissionFragment.graphql';

export type UsePermissionProps = {
    perform: Permissions,
    sustain?: boolean,
    noCache?: boolean,
};

export type UsePreloadedPermissionsProps = Omit<UsePermissionProps, 'noCache'> & {
    queryRef: PermissionRef,
};

export function usePreloadedPermission(props: UsePreloadedPermissionsProps): boolean {
    const {perform, sustain = false, queryRef} = props;

    const {permissions} = useFragment(
        graphql`
            fragment usePermissionFragment on Capabilities
            @argumentDefinitions(
                requiredPermissions: {
                    type: "[ApiPermission!]!"
                }
            ) {
                permissions(requiredPermissions: $requiredPermissions)
            }
        `,
        queryRef,
    );

    const [initial] = useState(permissions.includes(perform));

    return sustain ? initial : permissions.includes(perform);
}

export function usePermission(props: UsePermissionProps): boolean {
    const {perform, sustain = false, noCache = false} = props;
    const {organizationSlug, workspaceSlug, applicationSlug} = useParams<'application.settings'>();

    const {viewer, organization} = useLazyLoadQuery<usePermissionQuery>(
        graphql`
            query usePermissionQuery(
                $organizationSlug: ReadableId
                $workspaceSlug: ReadableId
                $applicationSlug: ReadableId
                $requiredPermission: ApiPermission!
            ) {
                viewer {
                    id
                    capabilities {
                        permissions(requiredPermissions: [$requiredPermission])
                    }
                }
                organization(slug: $organizationSlug) {
                    capabilities {
                        permissions(requiredPermissions: [$requiredPermission])
                    }
                    workspace(slug: $workspaceSlug) {
                        capabilities {
                            permissions(requiredPermissions: [$requiredPermission])
                        }
                        application(slug: $applicationSlug) {
                            capabilities {
                                permissions(requiredPermissions: [$requiredPermission])
                            }
                        }
                    }
                }
            }
        `,
        {
            requiredPermission: perform,
            organizationSlug: organizationSlug,
            workspaceSlug: workspaceSlug,
            applicationSlug: applicationSlug,
        },
        {fetchPolicy: noCache ? 'network-only' : 'store-or-network'},
    );

    const requiredPermissions = useMemo(
        () => [
            ...viewer.capabilities.permissions,
            ...organization?.capabilities.permissions ?? [],
            ...organization?.workspace?.capabilities.permissions ?? [],
            ...organization?.workspace?.application?.capabilities.permissions ?? [],
        ],
        [viewer, organization],
    );

    const [initial] = useState(requiredPermissions.includes(perform));

    return sustain
        ? initial
        : requiredPermissions.includes(perform);
}
