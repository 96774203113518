import {BoxStyle} from '@croct-tech/application-ui/components/Box';

export const style: BoxStyle = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    marginX: '6',

    '& > .heading': {
        color: 'admin.pages.notFoundPage.heading',
        marginTop: 12,
    },

    '& > .subtitle': {
        color: 'admin.pages.notFoundPage.subtitle',
        fontSize: 3,
        lineHeight: 'normal',
        marginBottom: 6,
        marginTop: 4,
    },
};
