import {useMemo} from 'react';
import {useMatch} from 'react-router-dom';
import {To} from '../components/RouterProvider';
import {useRouter} from './useRouter';

export type RouteMatchProps = {
    to: To,
    partial?: boolean,
};

export function useRouteMatch({to, partial = false}: RouteMatchProps): boolean {
    const {createPath} = useRouter();
    const resolvedPathname = useMemo(() => createPath(to), [to, createPath]);
    const match = useMatch({path: resolvedPathname, end: !partial});

    return match !== null;
}
