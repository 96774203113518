export const translationKey = 'admin:component.adminNotificationBar';

export type AdminNotificationBarTranslations = {
    mau: {
        message: {
            limitReached: string,
            limitApproaching: string,
            limitAlmostReached: string,
            support: string,
        },
    },
    offlineStatus: {
        message: string,
    },
    integration: {
        message: string,
    },
};
