import {FunctionComponent} from 'react';
import {Box} from '@croct-tech/application-ui/components/Box';
import {Button} from '@croct-tech/application-ui/components/Button';
import {Heading} from '@croct-tech/application-ui/components/Heading';
import {ArrowRightIcon} from '@croct-tech/application-ui/components/Icons/Outline/ArrowRightIcon';
import {Trans} from '@croct-tech/application-ui/components/Trans';
import {useTranslation} from '@croct-tech/application-ui/hooks/useTranslation';
import {RouteLink} from '../../../components/RouteLink';
import {Page} from '../Page';
import {Illustration} from './Illustration';
import {style} from './styles';
import {translationKey} from './translations';

export const NotFoundPage: FunctionComponent = () => {
    const {t} = useTranslation(translationKey);

    return (
        <Page title={t('title')}>
            <Box style={style}>
                <div className="illustration">
                    <Illustration />
                </div>
                <div className="heading">
                    <Heading lineHeight="tight" level={2} value={t('title')} />
                </div>
                <p className="subtitle">
                    {t('subtitle')}
                </p>
                <Button
                    as={RouteLink}
                    variant="secondary"
                    size="md"
                    width={{_: 'full', sm: 'auto'}}
                    appearance="important"
                    icon={ArrowRightIcon}
                    iconPlacement="suffix"
                    to="account.home"
                >
                    <Trans i18nKey={`${translationKey}.action.home`}>
                        <span>
                            <strong />
                        </span>
                    </Trans>
                </Button>
            </Box>
        </Page>
    );
};
