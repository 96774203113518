import type {FunctionComponent, PropsWithChildren} from 'react';
import {CroctProvider} from '@croct/plug-react';
import {useScope} from '../../hooks/useScope';
import {getEnvVar} from '../../utils/getEnvVar';

const CROCT_BASE_ENDPOINT_URL = process.env.REACT_APP_CROCT_BASE_ENDPOINT_URL;
const CROCT_CID_ASSIGNER_ENDPOINT_URL = process.env.REACT_APP_CROCT_CID_ASSIGNER_ENDPOINT_URL;

export const CROCT_ADMIN_APP_ID = getEnvVar('REACT_APP_CROCT_ADMIN_APP_ID');
export const CROCT_SITE_APP_ID = getEnvVar('REACT_APP_CROCT_SITE_APP_ID');

export const PersonalizationProvider: FunctionComponent<PropsWithChildren> = ({children}) => {
    const {user: {id}} = useScope();

    return (
        <CroctProvider
            userId={id}
            appId={id === null ? CROCT_SITE_APP_ID : CROCT_ADMIN_APP_ID}
            test={process.env.REACT_APP_CROCT_TEST_MODE === 'true'}
            {...(CROCT_BASE_ENDPOINT_URL !== undefined ? {baseEndpointUrl: CROCT_BASE_ENDPOINT_URL} : {})}
            {...(CROCT_CID_ASSIGNER_ENDPOINT_URL !== undefined
                ? {cidAssignerEndpointUrl: CROCT_CID_ASSIGNER_ENDPOINT_URL}
                : {}
            )}
        >
            {children}
        </CroctProvider>
    );
};
