export enum GlobalPermission {
    BYPASS_TERM_ACCEPTANCE = 'global_bypassTermAcceptance'
}

export enum OrganizationPermission {
    CREATE = 'organization_create',
    LIST = 'organization_list',
    VIEW = 'organization_view',
    VIEW_SETTINGS = 'organization_viewSettings',
    UPDATE_SETTINGS = 'organization_updateSettings',
    DELETE = 'organization_delete',
    UPDATE_SUBSCRIPTION = 'organization_setSubscription',
    ACCEPT_TERMS = 'organization_acceptTerms',
}

export enum OrganizationMemberPermission {
    INVITE = 'organization_member_invite',
    VIEW = 'organization_member_view',
    UPDATE = 'organization_member_update',
    REMOVE = 'organization_member_remove',
}

export enum WorkspacePermission {
    CREATE = 'workspace_create',
    LIST = 'workspace_list',
    VIEW = 'workspace_view',
    VIEW_SETTINGS = 'workspace_viewSettings',
    UPDATE_SETTINGS = 'workspace_updateSettings',
    DELETE = 'workspace_delete',
}

export enum WorkspaceMemberPermission {
    INVITE = 'workspace_member_invite',
    VIEW = 'workspace_member_view',
    UPDATE = 'workspace_member_update',
    REMOVE = 'workspace_member_remove',
}

export enum WorkspaceAudiencePermission {
    VIEW = 'workspace_audience_view',
    CREATE = 'workspace_audience_create',
    UPDATE = 'workspace_audience_update',
    DELETE = 'workspace_audience_delete',
}

export enum WorkspaceSlotPermission {
    VIEW = 'workspace_slot_view',
    CREATE = 'workspace_slot_create',
    UPDATE = 'workspace_slot_update',
    DELETE = 'workspace_slot_delete',
}

export enum WorkspaceComponentPermission {
    VIEW = 'workspace_component_view',
    CREATE = 'workspace_component_create',
    UPDATE = 'workspace_component_update',
    DELETE = 'workspace_component_delete',
}

export enum WorkspaceLocalePermission {
    VIEW = 'workspace_locale_view',
    CREATE = 'workspace_locale_create',
    UPDATE = 'workspace_locale_update',
    DELETE = 'workspace_locale_delete',
}

export enum ExperiencePermission {
    VIEW = 'experience_view',
    CREATE = 'experience_create',
    PUBLISH = 'experience_publish',
    PAUSE = 'experience_pause',
    ARCHIVE = 'experience_archive',
    RESTORE = 'experience_restore',
    UPDATE = 'experience_update',
    UPDATE_DRAFT = 'experience_updateDraft',
}

export enum ExperimentPermission {
    VIEW = 'experiment_view',
    CREATE = 'experiment_create',
    START = 'experiment_start',
    PAUSE = 'experiment_pause',
    FINISH = 'experiment_finish',
    UPDATE_DRAFT = 'experiment_updateDraft',
}

export enum ApplicationPermission {
    CREATE = 'application_create',
    LIST = 'application_list',
    VIEW = 'application_view',
    VIEW_SETTINGS = 'application_viewSettings',
    UPDATE_SETTINGS = 'application_updateSettings',
    DELETE = 'application_delete',
}

export enum ApplicationApiKeyPermission {
    CREATE = 'application_apiKey_create',
    DELETE = 'application_apiKey_delete',
}

export enum AccountPermission {
    CREATE = 'account_create',
    VIEW = 'account_view',
    VIEW_PROFILE = 'account_viewProfile',
    LIST = 'account_list',
    UPDATE = 'account_update',
    DELETE = 'account_delete',
    SIGN_UP = 'account_signUp',
    SIGN_IN = 'account_signIn',
    RESET_PASSWORD = 'account_resetPassword',
}

export enum AccountMembershipPermission {
    VIEW = 'account_membership_view',
    LIST = 'account_membership_list',
    QUIT = 'account_membership_quit',
    DECLINE_INVITE = 'account_membership_declineInvitation',
}

export enum AccountNotificationPermission {
    READ = 'account_notification_read',
}

export type Permissions = Extract<
    | GlobalPermission
    | OrganizationPermission
    | OrganizationMemberPermission
    | WorkspacePermission
    | WorkspaceMemberPermission
    | WorkspaceAudiencePermission
    | WorkspaceComponentPermission
    | WorkspaceSlotPermission
    | WorkspaceLocalePermission
    | ExperiencePermission
    | ExperimentPermission
    | ApplicationPermission
    | ApplicationApiKeyPermission
    | AccountPermission
    | AccountMembershipPermission
    | AccountNotificationPermission,
    string
>;

export enum OrganizationRole {
    MANAGER = 'MANAGER',
    MAINTAINER = 'MAINTAINER',
    OWNER = 'OWNER',
    CUSTOM = 'CUSTOM'
}

export enum WorkspaceRole {
    MANAGER = 'MANAGER',
    MAINTAINER = 'MAINTAINER',
    OWNER = 'OWNER',
    CUSTOM = 'CUSTOM'
}
