import {WarningCircleIcon} from '@croct-tech/application-ui/components/Icons/Solid/WarningCircleIcon';
import {WarningTriangleIcon} from '@croct-tech/application-ui/components/Icons/Solid/WarningTriangleIcon';
import {InfoCircleIcon} from '@croct-tech/application-ui/components/Icons/Solid/InfoCircleIcon';
import {BulbIcon} from '@croct-tech/application-ui/components/Icons/Solid/BulbIcon';
import {SparklesIcon} from '@croct-tech/application-ui/components/Icons/Solid/SparklesIcon';
import {ClockIcon} from '@croct-tech/application-ui/components/Icons/Solid/ClockIcon';
import {CloudStrikeIcon} from '@croct-tech/application-ui/components/Icons/Outline/CloudStrikeIcon';
import type {Icon} from '@croct-tech/application-ui/components/Icons';
import {NotificationBar} from '@croct-tech/application-ui/components/NotificationBar';
import {Reveal} from '@croct-tech/application-ui/components/Reveal';
import {Trans} from '@croct-tech/application-ui/components/Trans';
import {useTranslation} from '@croct-tech/application-ui/hooks/useTranslation';
import {useContent} from '@croct/plug-react';
import {useLocale} from '@croct-tech/application-ui/hooks/useLocale';
import {Notification as SlotNotification} from '@croct/plug/slot';
import graphql from 'babel-plugin-relay/macro';
import {Fragment, FunctionComponent, ReactElement} from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {useParams, useLocation} from 'react-router-dom';
import {PlugIcon} from '@croct-tech/application-ui/components/Icons/Outline/PlugIcon';
import {Link} from '@croct-tech/application-ui/components/Link';
import {renderMarkdown} from '../../../utils/markdown';
import {useOnlineStatus} from '../../../hooks/useOnlineStatus';
import {AdminNotificationBarQuery} from './__generated__/AdminNotificationBarQuery.graphql';
import {translationKey} from './translations';
import {useDismissedNotificationStorage} from './useDismissedNotificationStorage';
import {useSlotContext} from '../../../hooks/useSlotContext';
import {SupportLink, ContactSubject, Quota} from '../SupportLink';
import {RouteLink} from '../../../components/RouteLink';
import {useGoalCompletedTracker} from '../GoalCompletedTracker/useGoalCompletedTracker';
import {useRouteMatch} from '../../../hooks/useRouteMatch';

type MauNotificationBarProps = {
    usage: number,
    onDismiss: () => void,
};

const MauNotificationBar: FunctionComponent<MauNotificationBarProps> = props => {
    const {usage, onDismiss} = props;
    const {t} = useTranslation(translationKey);

    const getMessage = (): string | ReactElement => {
        switch (usage) {
            case 75:
            case 85:
                return (
                    <Trans i18nKey={`${translationKey}.mau.message.limitApproaching`} values={{limitReached: usage}}>
                        <b />
                    </Trans>
                );

            case 95:
                return (
                    <Trans i18nKey={`${translationKey}.mau.message.limitAlmostReached`}>
                        <b />
                        <SupportLink
                            underlined
                            support={{
                                subject: ContactSubject.LIMIT_INCREASE,
                                resource: Quota.MAU,
                            }}
                        />
                    </Trans>
                );

            case 100:
            default:
                return t('mau.message.limitReached');
        }
    };

    return (
        <NotificationBar
            icon={usage < 100 ? WarningTriangleIcon : WarningCircleIcon}
            appearance={usage < 100 ? 'cautious' : 'dangerous'}
            message={getMessage()}
            dismissible={usage < 100}
            onDismiss={onDismiss}
        />
    );
};

const OfflineStatusNotificationBar: FunctionComponent = () => (
    <NotificationBar
        icon={CloudStrikeIcon}
        appearance="cautious"
        message={(
            <Trans i18nKey={`${translationKey}.offlineStatus.message`}>
                <b />
            </Trans>
        )}
    />
);

const IntegrationNotificationBar: FunctionComponent = () => {
    const trackGoalCompleted = useGoalCompletedTracker();
    const {organizationSlug, workspaceSlug} = useParams<'workspace.home'>();

    return (
        <NotificationBar
            icon={PlugIcon}
            appearance="emphasized"
            message={(
                <Trans i18nKey={`${translationKey}.integration.message`}>
                    <RouteLink
                        underlined
                        as={Link}
                        onClick={() => trackGoalCompleted('notification-bar-integration-click')}
                        to={{
                            path: 'workspace.integration',
                            params: {
                                organizationSlug: organizationSlug,
                                workspaceSlug: workspaceSlug,
                            },
                        }}
                    />
                </Trans>
            )}
        />
    );
};

const iconMap: Record<SlotNotification['icon'], Icon> = {
    info: InfoCircleIcon,
    idea: BulbIcon,
    sparkles: SparklesIcon,
    attention: WarningCircleIcon,
    alert: WarningTriangleIcon,
    clock: ClockIcon,
};

type NotificationTopBarProps = SlotNotification & {
    onDismiss: () => void,
};

export const NotificationTopBar: FunctionComponent<NotificationTopBarProps> = props => {
    const {icon, appearence, content, dismissible, onDismiss} = props;

    return (
        <NotificationBar
            icon={iconMap[icon]}
            appearance={appearence}
            dismissible={dismissible}
            onDismiss={onDismiss}
            message={(
                <Fragment>
                    {renderMarkdown(content, {link: {underlined: true}})}
                </Fragment>
            )}
        />
    );
};

function useAdminNotification(): NotificationBar | null {
    const {organizationSlug, workspaceSlug, applicationSlug} = useParams<'application.dashboard'>();
    const onlineStatus = useOnlineStatus();
    const {locale} = useLocale();
    const {key} = useLocation();
    const slotContext = useSlotContext();
    const {notification} = useContent('admin-notification-top-bar@2', {
        preferredLocale: locale.code,
        fallback: {notification: null},
        expiration: 2000,
        cacheKey: key,
        ...(slotContext !== null ? {attributes: slotContext} : {}),
    });
    const isIntegrationPage = useRouteMatch({
        to: {
            path: 'workspace.integration',
            params: {
                organizationSlug: organizationSlug ?? '',
                workspaceSlug: workspaceSlug ?? '',
            },
        },
    });

    const {organization} = useLazyLoadQuery<AdminNotificationBarQuery>(
        graphql`
            query AdminNotificationBarQuery (
                $organizationSlug: ReadableId,
                $workspaceSlug: ReadableId,
                $applicationSlug: ReadableId,
            ) @raw_response_type {
                organization(slug: $organizationSlug) {
                    id
                    quotas @required(action: LOG) {
                        remainingMonthlyActiveUsers
                        monthlyActiveUsers
                    }
                    workspace(slug: $workspaceSlug) {
                        receivingTraffic
                        application(slug: $applicationSlug) {
                            applicationStatus
                        }
                    }
                }
            }
        `,
        {
            organizationSlug: organizationSlug,
            workspaceSlug: workspaceSlug,
            applicationSlug: applicationSlug,
        },
    );

    const {has, add} = useDismissedNotificationStorage();

    if (onlineStatus === false) {
        return {
            Component: OfflineStatusNotificationBar,
            props: {},
        } satisfies NotificationBar<'offlineStatus'>;
    }

    if (organization !== null) {
        if (
            !isIntegrationPage
            && (organization.workspace?.receivingTraffic === 'NEVER_RECEIVED_TRAFFIC'
            || organization.workspace?.application?.applicationStatus === 'NEVER_RECEIVED_TRAFFIC')
        ) {
            return {
                Component: IntegrationNotificationBar,
                props: {},
            } satisfies NotificationBar<'integration'>;
        }

        const threshold = getMauThreshold(
            organization.quotas.monthlyActiveUsers,
            organization.quotas.remainingMonthlyActiveUsers,
        );

        const mauNotificationId = `mau-${threshold}-${new Date().getMonth() + 1}-[${organization.id}]`;

        if (threshold > 0 && !has(mauNotificationId)) {
            return {
                Component: MauNotificationBar,
                props: {
                    usage: threshold,
                    onDismiss: (): void => add(mauNotificationId),
                },
            } satisfies NotificationBar<'mau'>;
        }
    }

    if ((notification ?? null) !== null && !has(notification!.notificationId)) {
        return {
            Component: NotificationTopBar,
            props: {
                ...notification!,
                onDismiss: () => add(notification!.notificationId),
            },
        } satisfies NotificationBar<'notificationTopBar'>;
    }

    return null;
}

function getMauThreshold(limit: number, remaining: number): number {
    // Calculate the percentage of the limit reached in steps of 5%
    const percentage = Math.floor(Math.ceil(((limit - remaining) * 100) / limit) / 5) * 5;
    const threshold = [100, 95, 85, 75].find(limitReached => percentage >= limitReached);

    return threshold ?? 0;
}

type NotificationType = {
    mau: MauNotificationBarProps,
    offlineStatus: Record<never, never>,
    integration: Record<never, never>,
    notificationTopBar: NotificationTopBarProps,
};

type NotificationBar<T extends keyof NotificationType = keyof NotificationType> = {
    [K in T]: {
        Component: FunctionComponent<NotificationType[K]>,
        props: NotificationType[K],
    }
}[T];

export const AdminNotificationBar: FunctionComponent = () => {
    const Notification = useAdminNotification();

    if (Notification === null) {
        return null;
    }

    return (
        <Reveal id="notification-bar">
            {renderNotificationBar(Notification)}
        </Reveal>
    );
};

function renderNotificationBar<T extends keyof NotificationType>(notification: NotificationBar<T>): ReactElement {
    const {Component, props} = notification;

    return <Component {...props} />;
}
