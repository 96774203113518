export const WEBSITE_BASE_URL = 'https://croct.com';
export const DOCS_BASE_URL = 'https://docs.croct.com';
export const CROCT_SLACK_COMMUNITY_URL = 'https://croct.link/community';
const DOCS_REFERENCE_SCHEMA = `${DOCS_BASE_URL}/reference/content/schema`;

const CROCT_GITHUB_URL = 'https://github.com/croct-tech';

export enum GitHubLink {
    PLUG_JS = `${CROCT_GITHUB_URL}/plug-js`,
    PLUG_REACT = `${CROCT_GITHUB_URL}/plug-react`,
    PLUG_NEXT = `${CROCT_GITHUB_URL}/plug-next`,
}

export enum WebsiteLink {
    CONTACT_SUPPORT = `${WEBSITE_BASE_URL}/contact/support`,
}

export enum DocumentationLink {
    ORGANIZATION_ROLES = `${DOCS_BASE_URL}/explanation/hierarchy/roles-and-permissions#organization-roles`,
    SCHEMA = `${DOCS_REFERENCE_SCHEMA}#schema`,
    LIST_ITEM_LABEL_PROP = `${DOCS_REFERENCE_SCHEMA}#list-itemLabel-prop`,
    STRUCTURE_ATTRIBUTE_LABEL_PROP = `${DOCS_REFERENCE_SCHEMA}#structure-attribute-label-prop`,
    STRUCTURE_DESCRIPTION_PROP = `${DOCS_REFERENCE_SCHEMA}#structure-description-prop`,
    STRUCTURE_ATTRIBUTE_DESCRIPTION_PROP = `${DOCS_REFERENCE_SCHEMA}#structure-attribute-description-prop`,
    TEXT_CHOICE_LABEL_PROP = `${DOCS_REFERENCE_SCHEMA}#text-choice-label-prop`,
    TEXT_CHOICE_DESCRIPTION_PROP = `${DOCS_REFERENCE_SCHEMA}#text-choice-description-prop`,
    COMPONENT_LIMITS = `${DOCS_BASE_URL}/explanation/component#limits`,
    COMPONENT_BEST_PRACTICES = `${DOCS_BASE_URL}/immersion/guides/component-design`,
    UNION_DESCRIPTION_PROP = `${DOCS_REFERENCE_SCHEMA}#union-description-prop`,
}
