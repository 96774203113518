import {ColorMode} from '@croct-tech/application-ui/styles/colorMode';
import {lightMode as baseMode, palette} from '@croct-tech/application-ui/styles/lightMode';
import {alpha} from '@croct-tech/application-ui/styles/alpha';

export const lightMode: ColorMode = {
    ...baseMode,
    admin: {
        component: {
            announcementCard: {
                title: palette.gray['600'],
                description: palette.gray['500'],
                highlight: palette.indigo['500'],
            },
            avatarEditor: {
                avatarUploader: {
                    cropper: {
                        overlay: `${palette.gray['700']}80`,
                    },
                    dropzone: {
                        background: palette.gray['50'],
                        border: palette.gray['200'],
                        backgroundDragging: palette.indigo['50'],
                        borderDragging: palette.indigo['200'],
                        backgroundHover: palette.gray['100'],
                        borderHover: palette.gray['300'],
                        uploadText: palette.indigo['600'],
                        uploadTextNeutral: palette.gray['600'],
                        illustration: {
                            backgroundNeutral: palette.gray['200'],
                            shapeNeutral: palette.gray['400'],
                            backgroundEmphasized: palette.indigo['200'],
                            shapeEmphasized: palette.indigo['400'],
                        },
                    },
                    uploadError: {
                        title: palette.gray['600'],
                        message: palette.gray['500'],
                    },
                },
                editableAvatar: {
                    background: `${palette.gray['600']}99`,
                    icon: palette.white,
                    text: palette.white,
                },
                inlineAvatarUploader: {
                    background: palette.gray['100'],
                },
            },
            blurOverlay: {
                background: alpha(palette.white, 0.4),
            },
            cqlEditor: {
                background: palette.white,
                backgroundDisabled: palette.gray['50'],
                border: palette.gray['300'],
                borderInvalid: palette.red['300'],
                borderDisabled: palette.gray['200'],
                placeholderText: palette.gray['400'],
                textDisabled: palette.gray['400'],
                text: palette.gray['600'],
            },
            dashboard: {
                integrationAlert: {
                    title: palette.gray[600],
                    description: palette.gray[500],
                    border: palette.gray[100],
                    avatar: {
                        shadow: alpha(palette.indigo[600], 0.2),
                        border: alpha(palette.white, 0.2),
                    },
                    connector: {
                        background: palette.indigo[100],
                        circle: {
                            background: palette.white,
                            border: palette.indigo[300],
                        },
                        indicator: {
                            from: 'transparent',
                            middle: palette.indigo[300],
                            to: 'transparent',
                        },
                    },
                    background: {
                        solid: palette.white,
                        glow: {
                            left: {
                                from: 'transparent',
                                to: alpha(palette.indigo[100], 0.15),
                            },
                            center: {
                                from: palette.purple[500],
                                to: 'transparent',
                            },
                            right: {
                                from: alpha(palette.emerald[200], 0.15),
                                to: 'transparent',
                            },
                        },
                        fade: {
                            from: 'transparent',
                            to: palette.white,
                        },
                    },
                },
            },
            disposableFilter: {
                icon: palette.gray['400'],
            },
            noSearchResults: {
                title: palette.gray['600'],
                description: palette.gray['500'],
            },
            header: {
                border: palette.gray['100'],
            },
            serviceTermsAcceptanceModal: {
                text: palette.gray['500'],
                lastUpdate: palette.gray['400'],
            },
            serviceTerms: {
                text: palette.gray['500'],
            },
            sidebarMenu: {
                background: palette.white,
                border: palette.gray['100'],
                homeButton: {
                    background: palette.white,
                    icon: palette.gray['400'],
                    text: palette.gray['500'],
                    shadow: `${palette.gray['800']}0D`,
                },
                sidebarMenuSection: {
                    title: palette.gray['400'],
                },
                sidebarMenuItem: {
                    backgroundSelected: palette.indigo['50'],
                    icon: palette.gray['400'],
                    iconHover: palette.gray['500'],
                    iconSelected: palette.indigo['500'],
                    text: palette.gray['500'],
                    textHover: palette.gray['600'],
                    textSelected: palette.indigo['600'],
                },
            },
            topBar: {
                background: palette.white,
                border: palette.gray['100'],
                divider: palette.gray['50'],
                icon: palette.gray['400'],
                notificationInbox: {
                    markAsRead: palette.indigo['500'],
                    border: palette.gray['100'],
                    emptyStateText: palette.gray['600'],
                    loading: palette.gray['400'],
                    markAsReadHover: palette.indigo['600'],
                    title: palette.gray['700'],
                    indicator: palette.emerald['400'],
                    notification: {
                        border: palette.gray['100'],
                        neutralText: palette.gray['400'],
                        text: palette.gray['600'],
                        timestamp: palette.gray['400'],
                    },
                    unreadBar: {
                        background: palette.indigo['50'],
                        text: palette.indigo['500'],
                    },
                },
            },
            widget: {
                background: palette.white,
                border: palette.gray['100'],
                title: palette.gray['500'],
                emptyState: {
                    title: palette.gray['500'],
                    soleTitle: palette.gray['400'],
                    description: palette.gray['400'],
                },
                loadingState: {
                    heading: palette.gray['500'],
                    subheading: palette.gray['400'],
                },
                statsWidget: {
                    border: palette.gray['100'],
                    comparisonDescription: palette.gray['500'],
                    empty: palette.gray['400'],
                    tooltipIcon: palette.gray['400'],
                    value: palette.gray['600'],
                },
                tableWidget: {
                    dataTable: {
                        bodyCell: palette.gray['500'],
                        empty: palette.gray['400'],
                        headerCell: palette.gray['500'],
                        headerIcon: palette.gray['400'],
                        hint: palette.gray['400'],
                        numericCell: {
                            barBackground: palette.indigo['300'],
                        },
                        rowMarker: {
                            badgeBackground: palette.gray['100'],
                            marker: palette.gray['600'],
                        },
                    },
                },
            },
        },
        layout: {
            authFrame: {
                background: palette.white,
                border: palette.gray['100'],
                description: palette.gray['500'],
                heading: palette.gray['600'],
            },
            authLayout: {
                border: palette.gray['100'],
            },
            stackedLayout: {
                background: palette.gray['50'],
            },
        },
        module: {
            application: {
                component: {
                    applicationBreadcrumb: {
                        environment: palette.gray['400'],
                    },
                    applicationMenu: {
                        border: palette.gray['100'],
                        name: palette.gray['500'],
                        nameHover: palette.gray['600'],
                    },
                },
                page: {
                    apiKeysPage: {
                        background: palette.white,
                        title: palette.gray['700'],
                        icon: palette.gray['400'],
                    },
                    integrationPage: {
                        background: palette.white,
                        title: palette.gray['700'],
                        description: palette.gray['500'],
                        border: palette.gray['100'],
                        statusText: palette.gray['500'],
                        librarySection: {
                            text: palette.gray['500'],
                            libraryCard: {
                                text: palette.gray['600'],
                            },
                        },
                    },
                    settingsPage: {
                        background: palette.white,
                        title: palette.gray['700'],
                        icon: palette.gray['400'],
                        webSettings: {
                            profileSection: {
                                applicationIcon: {
                                    text: palette.gray['600'],
                                },
                            },
                            securitySection: {
                                description: palette.gray['500'],
                                originTable: {
                                    disabled: palette.gray['400'],
                                },
                            },
                        },
                    },
                },
            },
            authentication: {
                page: {
                    authPage: {
                        preHeading: palette.gray['400'],
                        subheading: palette.gray['500'],
                    },
                    forgotPasswordPage: {
                        text: palette.gray['600'],
                    },
                    resetPasswordPendingPage: {
                        description: palette.gray['500'],
                    },
                    welcomePage: {
                        title: palette.gray['600'],
                        description: palette.gray['500'],
                        mistypedEmail: palette.gray['500'],
                    },
                },
            },
            content: {
                component: {
                    hybridComponentForm: {
                        separator: palette.gray['400'],
                        componentCard: {
                            background: palette.white,
                            border: palette.gray['100'],
                            name: palette.gray['600'],
                        },
                    },
                    schema: {
                        attributeTree: {
                            attributeRow: {
                                border: palette.gray['100'],
                                borderInvalid: palette.red['300'],
                                attributeText: palette.gray['600'],
                                attributeOptional: palette.gray['400'],
                                attributeOptionalDisabled: palette.gray['300'],
                                attributeTextDisabled: palette.gray['400'],
                                chevron: palette.gray['400'],
                                chevronInvalid: palette.red['400'],
                                chevronDisabled: palette.gray['300'],
                                dragHandleDisabled: palette.gray['300'],
                                privateIcon: palette.gray['400'],
                                privateIconDisabled: palette.gray['300'],
                            },
                            attributeType: {
                                backgroundHighlighted: palette.indigo['100'],
                                label: {
                                    normal: palette.gray['500'],
                                    dimmed: palette.gray['400'],
                                    highlighted: palette.indigo['600'],
                                },
                                icon: {
                                    normal: palette.gray['400'],
                                    dimmed: palette.gray['300'],
                                    highlighted: palette.indigo['400'],
                                },
                            },
                            errorAddon: {
                                icon: palette.red['400'],
                            },
                            highlight: {
                                text: palette.indigo['600'],
                                background: palette.indigo['100'],
                            },
                            infoRow: {
                                text: palette.gray['400'],
                            },
                            row: {
                                textDisabled: palette.gray['400'],
                                background: palette.gray[100],
                                backgroundHover: alpha(palette.gray['50'], 0.8),
                                backgroundInvalid: palette.red['300'],
                            },
                            attributeSubtree: {
                                text: palette.gray['400'],
                            },
                            unionSelector: {
                                chevron: palette.gray['400'],
                                chevronHover: palette.gray['500'],
                                chevronDimmed: palette.gray['300'],
                                chevronDimmedHover: palette.gray['400'],
                            },
                            unionSubtree: {
                                warningText: palette.red['400'],
                            },
                        },
                        componentSelector: {
                            warning: palette.red['400'],
                        },
                        schemaEditor: {
                            title: palette.gray['600'],
                            subtitle: palette.gray['500'],
                            conjunction: palette.gray['500'],
                            complexityIndicator: {
                                border: palette.gray['200'],
                                text: palette.gray['400'],
                                icon: palette.red['400'],
                            },
                            emptyStateIllustration: {
                                circle: palette.gray['100'],
                                card: palette.white,
                                cardText: palette.gray['400'],
                                cardShadow: palette.gray['100'],
                                textBox: palette.indigo['100'],
                                textIcon: palette.indigo['500'],
                                imageBox: palette.pink['100'],
                                imageIcon: palette.pink['400'],
                                booleanBox: palette.yellow['100'],
                                booleanIcon: palette.yellow['400'],
                            },
                        },
                        schemaForm: {
                            fileForm: {
                                separator: palette.gray['400'],
                            },
                            listForm: {
                                separator: palette.gray['400'],
                            },
                            numberForm: {
                                separator: palette.gray['400'],
                            },
                            plainTextForm: {
                                separator: palette.gray['400'],
                            },
                        },
                        schemaFormSection: {
                            header: {
                                title: palette.gray['400'],
                                icon: palette.gray['400'],
                            },
                        },
                        itemDrawer: {
                            itemDisplay: palette.gray['500'],
                        },
                    },
                },
                page: {
                    componentsPage: {
                        componentList: {
                            subtitle: palette.gray['500'],
                            title: palette.gray['700'],
                            componentCard: {
                                name: palette.gray['600'],
                                text: palette.gray['500'],
                                detail: {
                                    icon: palette.gray['400'],
                                    outline: palette.indigo['400'],
                                },
                            },
                            componentEmptyList: {
                                description: palette.gray['500'],
                                title: palette.gray['600'],
                            },
                        },
                    },
                    regularComponentPage: {
                        subtitle: palette.gray['500'],
                        title: palette.gray['700'],
                    },
                },
            },
            personalization: {
                component: {
                    contentForm: {
                        attributeField: {
                            compositeField: {
                                description: palette.gray['500'],
                            },
                            primitiveField: {
                                dynamicInput: {
                                    background: palette.gray['100'],
                                },
                                urlInput: {
                                    icon: palette.gray[400],
                                },
                            },
                            unionField: {
                                unionForm: {
                                    discriminatorSelector: {
                                        label: palette.gray[500],
                                        icon: palette.gray[400],
                                    },
                                },
                            },
                        },
                        uploader: {
                            progressRing: {
                                checkmark: {
                                    stroke: palette.indigo['400'],
                                },
                                circle: {
                                    backgroundStroke: palette.gray['200'],
                                    foregroundStroke: palette.indigo['400'],
                                },
                            },
                            uploadZone: {
                                background: palette.gray['50'],
                                icon: palette.gray['400'],
                                border: palette.gray['300'],
                                borderFocus: palette.gray['50'],
                                hover: {
                                    border: palette.gray['300'],
                                    borderFocus: palette.gray['100'],
                                    background: palette.gray['100'],
                                    icon: palette.gray['500'],
                                },
                                disabled: {
                                    border: palette.gray['200'],
                                    icon: palette.gray['300'],
                                },
                                dragging: {
                                    border: palette.indigo['300'],
                                    borderFocus: palette.indigo['50'],
                                    background: palette.indigo['50'],
                                    icon: palette.indigo['400'],
                                },
                                invalid: {
                                    border: palette.red['300'],
                                    borderFocus: palette.red['50'],
                                },
                                rejected: {
                                    background: palette.red['50'],
                                    icon: palette.red['400'],
                                },
                            },
                        },
                    },
                    experiencePreviewDrawer: {
                        experiencePreviewForm: {
                            suffix: palette.gray['400'],
                        },
                    },

                    experienceEditor: {
                        audiencePanel: {
                            conjunction: palette.gray['400'],
                            heading: {
                                text: palette.gray['500'],
                                icon: palette.gray['400'],
                            },
                            audienceListItem: {
                                background: palette.white,
                                border: palette.gray['100'],
                                icon: palette.gray['300'],
                                outline: palette.indigo['400'],
                                text: palette.gray['600'],
                                marker: {
                                    background: palette.gray['100'],
                                    text: palette.gray['500'],
                                },
                            },
                        },
                        contentPanel: {
                            audienceGroupSelector: {
                                icon: palette.gray[400],
                                separator: palette.gray[400],
                                groupSelector: palette.gray[400],
                                nonEmptyList: palette.gray[500],
                                emptyListHover: palette.gray[500],
                                nonEmptyListHover: palette.gray[600],
                            },
                            contentVariant: {
                                formError: palette.red['500'],
                            },
                            slotContentItem: {
                                title: palette.gray['500'],
                                titleIcon: palette.gray['400'],
                                defaultContent: palette.gray['400'],
                                localizedContentStatusHovercard: {
                                    title: palette.gray['600'],
                                    name: palette.gray['500'],
                                    default: palette.gray['400'],
                                    icon: palette.gray['400'],
                                    iconFilled: palette.emerald['400'],
                                },
                            },
                        },
                        diffIndicator: {
                            heading: palette.gray['600'],
                            structureDiff: {
                                title: palette.gray['500'],
                            },
                            valueChange: {
                                icon: palette.gray['400'],
                                value: {
                                    previous: palette.gray['400'],
                                    current: palette.gray['600'],
                                },
                            },
                        },
                        experienceActionBar: {
                            icon: palette.gray['300'],
                            status: palette.gray['400'],
                        },
                        experimentPanel: {
                            variantList: {
                                title: palette.gray['700'],
                                description: palette.gray['500'],
                                error: palette.red['500'],
                                hintIcon: palette.gray['400'],
                                variantListItem: {
                                    baselineFlag: palette.gray['400'],
                                    text: palette.gray['500'],
                                },
                            },
                        },
                        experienceOptions: {
                            title: palette.gray['400'],
                            experienceOption: {
                                title: palette.gray['500'],
                                titleDisabled: palette.gray['400'],
                                border: palette.gray['100'],
                                borderInvalid: palette.red['300'],
                                borderHover: palette.gray['200'],
                                borderHoverInvalid: palette.red['300'],
                                icon: palette.gray['400'],
                                iconDisabled: palette.gray['300'],
                                outline: palette.indigo['400'],
                                outlineInvalid: palette.red['400'],
                                hint: palette.red['500'],
                            },
                            priorityOption: {
                                chevronDisabled: palette.gray['300'],
                            },
                            timeZoneOption: {
                                chevronDisabled: palette.gray['300'],
                            },
                            scheduleOption: {
                                chevronDisabled: palette.gray['300'],
                            },
                        },
                        priorityDrawer: {
                            priorityField: {
                                content: palette.gray['400'],
                            },
                            priorityPreview: {
                                icon: palette.gray['400'],
                                label: palette.gray['500'],
                                subtitle: palette.gray['500'],
                                listItem: {
                                    label: palette.gray['400'],
                                    status: palette.gray['400'],
                                    badge: {
                                        background: palette.gray['100'],
                                        label: palette.gray['400'],
                                    },
                                    current: {
                                        badge: {
                                            background: palette.indigo['100'],
                                            label: palette.indigo['500'],
                                        },
                                        label: palette.indigo['500'],
                                        status: palette.indigo['400'],
                                    },
                                },
                            },

                        },
                        slotPanel: {
                            slotList: {
                                separator: palette.gray['100'],
                            },
                            slotListItem: {
                                name: palette.gray['600'],
                            },
                        },
                        panelListSummary: {
                            separator: palette.gray['400'],
                        },
                    },
                    localeContentField: {
                        localeCard: {
                            background: palette.white,
                            flag: palette.gray['400'],
                            invalidContent: palette.orange['400'],
                            optional: palette.gray['400'],
                            selectComponent: palette.gray['400'],
                            text: palette.gray['500'],
                            textDisabled: palette.gray['400'],
                            border: {
                                color: palette.gray['100'],
                                hover: palette.gray['200'],
                            },
                        },
                    },
                    previewCard: {
                        filePreviewCard: {
                            background: palette.white,
                            border: palette.gray[200],
                            loadingHeader: palette.gray[400],
                            header: palette.gray[500],
                            icon: palette.gray[400],
                            separator: palette.gray[300],
                            audioPlayer: {
                                background: palette.gray[50],
                                currentTime: palette.gray[500],
                                remainingTime: palette.gray[400],
                            },
                            videoThumbnail: {
                                background: `${palette.gray[100]}CC`,
                                text: palette.gray[600],
                            },
                        },
                        pagePreviewCard: {
                            background: palette.gray[50],
                            text: palette.gray[500],
                            icon: palette.gray[400],
                        },
                    },
                    warningIndicator: {
                        icon: palette.orange['400'],
                    },
                },
                page: {
                    audiencesPage: {
                        background: palette.white,
                        audienceCard: {
                            text: palette.gray['600'],
                            detail: {
                                text: palette.gray['500'],
                                icon: palette.gray['400'],
                                outline: palette.indigo['400'],
                            },
                        },
                        audienceEmptyList: {
                            title: palette.gray['600'],
                            description: palette.gray['500'],
                        },
                    },
                    newAudiencePage: {
                        background: palette.white,
                    },
                    editAudiencePage: {
                        background: palette.white,
                    },
                    editExperiencePage: {
                        contentCard: {
                            background: palette.white,
                            border: palette.gray['100'],
                            title: palette.gray['600'],
                            description: palette.gray['500'],
                        },
                    },
                    slotsPage: {
                        background: palette.white,
                        slotEmptyList: {
                            title: palette.gray['600'],
                            description: palette.gray['500'],
                        },
                        slotList: {
                            subtitle: palette.gray['500'],
                            title: palette.gray['700'],
                            slotCard: {
                                name: palette.gray['600'],
                                text: palette.gray['500'],
                                detail: {
                                    icon: palette.gray['400'],
                                    outline: palette.indigo['400'],
                                },
                            },
                        },
                    },
                    newSlotPage: {
                        title: palette.gray['700'],
                        subtitle: palette.gray['500'],
                    },
                    editSlotPage: {
                        title: palette.gray['700'],
                        subtitle: palette.gray['500'],
                    },
                    experiencesPage: {
                        experienceList: {
                            emptyList: {
                                title: palette.gray['600'],
                                description: palette.gray['500'],
                            },
                            experienceCard: {
                                title: palette.gray['600'],
                                status: palette.gray['500'],
                                dropdown: palette.gray['400'],
                                priority: palette.gray['400'],
                                icon: {
                                    draft: palette.gray['400'],
                                    active: palette.emerald['400'],
                                    paused: palette.orange['400'],
                                    archived: palette.gray['400'],
                                    testing: palette.gray['400'],
                                    scheduled: palette.gray['400'],
                                },
                            },
                        },
                    },
                    newExperiencePage: {
                        title: palette.gray['700'],
                    },
                    experiencePage: {
                        icon: {
                            active: palette.emerald['400'],
                            paused: palette.orange['400'],
                            archived: palette.gray['400'],
                            scheduled: palette.gray['400'],
                        },
                        lastUpdate: palette.gray['500'],
                        formerMember: palette.gray['400'],
                    },
                    experimentsPage: {
                        emptyState: {
                            title: palette.gray['600'],
                            description: palette.gray['500'],
                        },
                        experimentList: {
                            finished: palette.gray['400'],
                            experimentCard: {
                                background: palette.white,
                                text: palette.gray['600'],
                                icon: {
                                    active: palette.emerald[400],
                                    draft: palette.gray[300],
                                    paused: palette.orange[400],
                                    finished: palette.gray[300],
                                    scheduled: palette.gray[400],
                                },
                                detail: {
                                    text: palette.gray['500'],
                                    icon: palette.gray['400'],
                                },
                            },
                        },
                    },
                    newExperimentPage: {
                        title: palette.gray['700'],
                    },
                    experimentPage: {
                        icon: {
                            draft: palette.gray[300],
                            active: palette.emerald[400],
                            paused: palette.orange[400],
                            finished: palette.gray[300],
                            scheduled: palette.gray[400],
                        },
                        lastUpdate: palette.gray['500'],
                        formerMember: palette.gray['400'],
                    },
                    experimentOverviewPage: {
                        experimentStatus: {
                            decisionStatus: {
                                icon: palette.gray['400'],
                            },
                            statusCard: {
                                body: palette.gray['500'],
                                title: palette.gray['600'],
                                contrasted: {
                                    background: palette.gray['50'],
                                },
                                emphasized: {
                                    background: palette.indigo['50'],
                                    body: palette.indigo['500'],
                                    title: palette.indigo['600'],
                                },
                                neutral: {
                                    border: palette.gray['100'],
                                },
                            },
                        },
                    },
                },
            },
            organization: {
                component: {
                    organizationMenu: {
                        icon: palette.gray['400'],
                        iconHover: palette.gray['500'],
                        label: palette.gray['400'],
                        labelHover: palette.gray['500'],
                    },
                },
                page: {
                    membersPage: {
                        background: palette.white,
                        membersTable: {
                            avatar: palette.gray['600'],
                            disabled: palette.gray['400'],
                        },
                    },
                    settingsPage: {
                        background: palette.white,
                        profileSection: {
                            organizationLogo: {
                                title: palette.gray['600'],
                            },
                        },
                    },
                    workspacePage: {
                        background: palette.white,
                        emptyList: {
                            title: palette.gray['600'],
                            description: palette.gray['500'],
                        },
                        workspaceCard: {
                            text: palette.gray['600'],
                        },
                    },
                },
            },
            onboarding: {
                component: {
                    galleryCard: {
                        title: palette.gray['600'],
                        description: palette.gray['500'],
                        badge: {
                            background: palette.indigo['400'],
                            text: palette.white,
                        },
                        image: {
                            border: palette.gray['100'],
                            borderHover: palette.gray['200'],
                            featured: {
                                border: palette.indigo['200'],
                                borderHover: palette.indigo['300'],
                            },
                        },
                    },
                    onboardingHeader: {
                        skip: palette.gray['500'],
                        skipHover: palette.gray['600'],
                    },
                    navigatableStep: {
                        subheading: palette.gray['500'],
                    },
                },
                page: {
                    accountSetupPage: {
                        agreement: palette.gray['400'],
                    },
                    completedSetupPage: {
                        loadingState: {
                            preHeading: palette.gray['500'],
                            heading: palette.gray['600'],
                        },
                    },
                },
            },
            user: {
                component: {
                    organizationCard: {
                        name: palette.gray['600'],
                        description: palette.gray['500'],
                        icon: palette.gray['400'],
                    },
                },
                page: {
                    homePage: {
                        inviteList: {
                            inviteCard: {
                                name: palette.gray['600'],
                                description: palette.gray['500'],
                            },
                        },
                        organizationSection: {
                            emptyList: {
                                heading: palette.gray['600'],
                                label: palette.gray['500'],
                            },
                            emptySearchText: palette.gray['400'],
                        },
                    },
                    settingsPage: {
                        background: palette.white,
                        membershipSection: {
                            membershipCard: {
                                text: palette.gray['600'],
                            },
                            emptyText: palette.gray['400'],
                        },
                        profileSection: {
                            userAvatar: {
                                background: palette.gray['100'],
                            },
                        },
                    },
                },
            },
            workspace: {
                component: {
                    applicationCard: {
                        environment: palette.gray['400'],
                        text: palette.gray['600'],
                        type: palette.gray['400'],
                    },
                    interactiveTutorial: {
                        divider: palette.gray['100'],
                        emptyState: {
                            subheading: palette.gray['500'],
                        },
                        inviteMemberSection: {
                            header: {
                                icon: palette.gray[400],
                                title: palette.gray[500],
                                description: palette.gray[500],
                            },
                            info: {
                                anchor: palette.gray['400'],
                            },
                        },
                        resourceSection: {
                            title: palette.gray['400'],
                            card: {
                                text: palette.gray['500'],
                                textHover: palette.gray[700],
                                icon: palette.gray['400'],
                                iconHover: palette.indigo[400],
                                divider: palette.gray['100'],
                            },
                        },
                    },
                    helpBanner: {
                        background: palette.indigo[50],
                        title: palette.indigo[600],
                        description: palette.indigo[500],
                    },
                    workspaceMenu: {
                        border: palette.gray['100'],
                        organizationDropdown: {
                            icon: palette.gray['400'],
                            label: palette.gray['500'],
                            labelHover: palette.gray['600'],
                        },
                    },
                },
                page: {
                    applicationsPage: {
                        background: palette.white,
                        title: palette.gray['700'],
                        emptyList: {
                            title: palette.gray['600'],
                            description: palette.gray['500'],
                        },
                    },
                    integrationPage: {
                        workspaceHomeLink: {
                            name: palette.gray['500'],
                        },
                    },
                    membersPage: {
                        background: palette.white,
                        membersTable: {
                            avatar: palette.gray['600'],
                            disabled: palette.gray['400'],
                        },
                    },
                    settingsPage: {
                        background: palette.white,
                        profileSection: {
                            workspaceLogo: {
                                text: palette.gray['600'],
                            },
                        },
                        localeSection: {
                            localeTable: {
                                icon: palette.gray['400'],
                                default: palette.gray['400'],
                                text: palette.gray['600'],
                            },
                        },
                    },
                },
            },
        },
        pages: {
            errorPage: {
                background: palette.white,
                title: palette.gray['600'],
                subtitle: palette.gray['500'],
            },
            hierarchicalPage: {
                background: palette.white,
            },
            privacyPolicyPage: {
                highlight: palette.gray['700'],
            },
            restrictedAccessPage: {
                subtitle: palette.gray['500'],
                background: palette.white,
            },
            useTermsPage: {
                highlight: palette.gray['700'],
            },
            notFoundPage: {
                heading: palette.gray['600'],
                subtitle: palette.gray['500'],
            },
            offboardingPage: {
                background: palette.white,
                title: palette.gray['600'],
                subtitle: palette.gray['500'],
                text: palette.gray['500'],
            },
        },
    },
};
