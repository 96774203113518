if (URL.canParse === undefined) {
    URL.canParse = function canParse(value: string): boolean {
        try {
            // eslint-disable-next-line no-new -- Required to check if the URL is valid.
            new URL(value);

            return true;
        } catch {
            return false;
        }
    };
}
