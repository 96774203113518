import {useEffect, useState} from 'react';

const pingUrl = process.env.REACT_APP_ADMIN_ENDPOINT !== undefined
    ? `${process.env.REACT_APP_ADMIN_ENDPOINT}/ping`
    : 'http://localhost:4000/ping';

function checkConnection(): Promise<boolean> {
    return (
        new Promise<boolean>(resolve => {
            const isOffline = (): void => resolve(false);

            const xhr = new XMLHttpRequest();

            xhr.onerror = isOffline;
            xhr.ontimeout = isOffline;

            xhr.onreadystatechange = (): void => {
                if (xhr.readyState === xhr.HEADERS_RECEIVED) {
                    if (xhr.status !== 0) {
                        resolve(true);
                    } else {
                        isOffline();
                    }
                }
            };

            xhr.open('GET', pingUrl);
            xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, max-age=0');
            xhr.timeout = 5000;
            xhr.send();
        })
    );
}

export function useOnlineStatus(): boolean {
    const [isOnline, setOnline] = useState(true);

    useEffect(
        () => {
            function notifyReconnection(): void {
                setOnline(true);
            }

            const pollInterval = setInterval(
                () => checkConnection().then(setOnline),
                5000,
            );

            window.addEventListener('online', notifyReconnection);

            return (): void => {
                clearInterval(pollInterval);
                window.removeEventListener('online', notifyReconnection);
            };
        },
        [],
    );

    return isOnline;
}
