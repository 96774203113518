export enum Expertise {
    ENGINEERING = 'engineering',
    MARKETING = 'marketing',
    PRODUCT = 'product',
    DESIGN = 'design',
    OTHER = 'other'
}

export namespace Expertise {
    export function isValid(value: string): value is Expertise {
        for (const expertise of Object.values(Expertise)) {
            if (expertise === value) {
                return true;
            }
        }

        return false;
    }
}
